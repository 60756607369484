import React from 'react'
import {
  ActionButton,
  Box,
  Flex,
  Item,
  Placeholder,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GoalsTable } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Goals/GoalsTable'
import { SummarySection } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/SummarySection'
import Loader from '@components/CommonSC/Loader'
import {
  emptySummaryDescription,
  makeSummaryTitle,
  summaryDescription,
} from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/constants'
import { useGoalsFilters } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Goals/useGoalsFilters'
import { useGetPerformanceSummaryForGrowthPlan } from '@src/api/growthPlans'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { FunctionalSkillsTable } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Skills/FunctionalSkillsTable'
import { CulturalValuesTable } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Values/CulturalValuesTable'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import { ReviewCategory } from '@src/interfaces/performance'
import { useGoalsVisibilityBySummary } from '@src/features/Goals/useGoalsVisibilityBySummary'
import { useGetPerformanceReviewSummary } from '@src/api/performanceReview'
import { EntityTypes } from '@src/constants/api'
import { NoPersonalGoalsBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/NoPersonalGoalsBanner'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  reviewCycle: ReviewCyclesInterface | null
  isLoading: boolean
}

const placeholderHeight = 300

export const Summary = ({ employee, growthPlan, reviewCycle, isLoading }: Props) => {
  const { data: goalsFilters, isLoading: isGoalsFiltersLoading } = useGoalsFilters(
    EntityTypes.employee,
    employee.id,
    reviewCycle,
  )
  const {
    data: growthPlanPerformanceSummary,
    isLoading: isGrowthPlanPerformanceSummaryLoading,
  } = useGetPerformanceSummaryForGrowthPlan({
    employee_id: employee.id,
    target_seniority_id: growthPlan.target_seniority.id,
    target_specialisation_id: growthPlan.target_specialisation.id,
  })
  const { data: performanceSummary, isLoading: isPerformanceSummaryLoading } =
    useGetPerformanceReviewSummary(
      reviewCycle ? String(reviewCycle.id) : undefined,
      employee.id,
      reviewCycle?.category,
    )
  const {
    isFetching: isGoalsVisibilityFetching,
    isIndividualGoalsVisible,
    isTeamGoalsVisible,
  } = useGoalsVisibilityBySummary(performanceSummary, goalsFilters)

  const goalsEntityType =
    performanceSummary?.team && isTeamGoalsVisible
      ? EntityTypes.team
      : EntityTypes.employee
  const goalsEntityId =
    performanceSummary?.team && isTeamGoalsVisible
      ? performanceSummary.team.id
      : employee.id

  if (
    isLoading ||
    isGoalsFiltersLoading ||
    isGoalsVisibilityFetching ||
    isPerformanceSummaryLoading
  ) {
    return (
      <Widget height={placeholderHeight}>
        <Loader />
      </Widget>
    )
  }

  if (reviewCycle === null) {
    return (
      <Widget height={placeholderHeight}>
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Placeholder>
            <Placeholder.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-business/3D299.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-business/3D299@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-business/3D299@3x.png',
              }}
              size={88}
            />
            <Placeholder.Title>{emptySummaryDescription}</Placeholder.Title>
          </Placeholder>
        </Flex>
      </Widget>
    )
  }

  return (
    <Widget>
      <VStack>
        <Item>
          <Item.Content>
            <Item.Title>{makeSummaryTitle(reviewCycle)}</Item.Title>
            <Item.Description>{summaryDescription}</Item.Description>
          </Item.Content>
          <Item.Side>
            <Item.Value>
              <ActionButton
                useIcon="LinkExternalSimple"
                to={getReviewsUrl(
                  ReviewCategory.Performance,
                  String(reviewCycle.id),
                  employee.id,
                )}
                target="_blank"
                use={InternalLink}
                disabled={isLoading}
              >
                Detailed view
              </ActionButton>
            </Item.Value>
          </Item.Side>
        </Item>
        <SummarySection title="Goals" iconName="Target">
          {!isIndividualGoalsVisible && performanceSummary?.team && (
            <Box mb="s-16">
              <NoPersonalGoalsBanner team={performanceSummary.team} isPerformanceBanner />
            </Box>
          )}
          <GoalsTable
            entityType={goalsEntityType}
            entityId={goalsEntityId}
            reviewCycle={reviewCycle}
          />
        </SummarySection>
        <SummarySection title="Skills" iconName="Palette">
          <FunctionalSkillsTable
            data={growthPlanPerformanceSummary?.functional_skills ?? []}
            growthPlan={growthPlan}
            isLoading={isLoading || isGrowthPlanPerformanceSummaryLoading}
          />
        </SummarySection>
        <SummarySection title="Values" iconName="Heart">
          <CulturalValuesTable
            data={growthPlanPerformanceSummary?.culture_values ?? []}
            growthPlan={growthPlan}
            isLoading={isLoading || isGrowthPlanPerformanceSummaryLoading}
          />
        </SummarySection>
      </VStack>
    </Widget>
  )
}
