import { useEffect } from 'react'

import { useTableReturnType } from '@src/components/TableV2/hooks'
import { IdAndName } from '@src/interfaces'
import {
  EngagementResultInterface,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { OptionInterface } from '@src/interfaces/selectors'
import { toIdAndName } from '@src/utils/toIdAndName'

export const MAX_ITEMS_ALLOWED_FOR_ANALYSIS = 10

export type HeatmapDataGroupByOptions = IdAndName<string>

export type ViewMode = 'table' | 'heatmap'

export type UseHeatmapFiltersReturnType = {
  value: OptionInterface[]
  setValue: (newValue: Record<HeatmapDataGroupByOptions['id'], OptionInterface[]>) => void
  tableParams: string | undefined
  clearAll: VoidFunction
}
export type ResultsInterface = {
  viewMode: ViewMode
  surveyId: number
  selectedRound: IdAndName
  groupBy: HeatmapDataGroupByOptions
  heatmapFilters: UseHeatmapFiltersReturnType
  isLoading: boolean
  entity?: { type: EngagementResultsScope; id: number }
}

export const useApplySurveyRoundFilter = (
  table: useTableReturnType<EngagementResultInterface>,
  selectedRoundId: string,
) => {
  useEffect(() => {
    const currentSurveyRound = table.filterBy
      .find(({ columnName }) => columnName === 'survey_round_id')
      ?.filters.at(0)?.id
    if (selectedRoundId !== currentSurveyRound) {
      table.onFilterChange({
        columnName: 'survey_round_id',
        filters: [toIdAndName(selectedRoundId)],
        nonInheritable: true,
        nonResettable: true,
      })
    }
  }, [selectedRoundId])
}

export const getResultsInitialFilterBy = (selectedRoundId: string) => [
  {
    columnName: 'survey_round_id',
    filters: [toIdAndName(selectedRoundId)],
    nonInheritable: true,
    nonResettable: true,
  },
]
