import React, { useEffect } from 'react'
import pluralize from 'pluralize'
import assign from 'lodash/assign'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import { Box, Group, TransitionCollapse, VStack } from '@revolut/ui-kit'

import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { TimeOffPolicyBasicsStep } from '@src/interfaces/timeOff'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

import { ActivePolicyDraftWarning } from '../components/ActivePolicyDraftWarning'
import { NextTabButton } from './NextTabButton'
import { PolicyEditTabCommonProps } from './common'
import { useGetSelectors } from '@src/api/selectors'

const HalfDaySwitch = () => {
  const { values } = useLapeContext<TimeOffPolicyBasicsStep>()

  return (
    <TransitionCollapse in={values.unit?.id === 'day'}>
      <Group>
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Employees should be able to take half a day time off',
            description:
              'For example, should someone be able to take an afternoon or morning off.',
            transparent: true,
          }}
          checked={values.half_days?.id === 'yes'}
          name="half_days"
          onAfterChange={checked => {
            if (checked) {
              values.half_days = {
                id: 'yes',
                name: 'Can request half-days',
              }
            } else {
              values.half_days = {
                id: 'no',
                name: 'Can only request full days',
              }
            }
          }}
        />
      </Group>
    </TransitionCollapse>
  )
}

export const PolicyEditBasicsTab = ({
  copyFrom,
  setIsCopied,
  refetchBasicStepInfo,
  nextTabPath,
  onNavigateToNextTab,
}: PolicyEditTabCommonProps) => {
  const { values } = useLapeContext<TimeOffPolicyBasicsStep>()

  const { data: categoriesOptions = [] } = useGetSelectors(
    selectorKeys.time_off_policy_categories,
  )

  useEffect(() => {
    if (!copyFrom) {
      return
    }
    if (copyFrom.name) {
      values.name = `${copyFrom.name} - Copy`
    }
    if (
      copyFrom.category &&
      categoriesOptions?.some(category => isEqual(category, copyFrom.category))
    ) {
      values.category = copyFrom.category
    }
    assign(values, pick(copyFrom, ['category', 'paid', 'unit', 'balance_type']))
    setIsCopied()
  }, [copyFrom])

  const unit = pluralize(values.unit?.name || 'day').toLowerCase()

  return (
    <>
      {values.draft_has_active_policy && <ActivePolicyDraftWarning />}
      <VStack gap="s-16">
        <Box>
          <SectionTitle title="What should your policy be called?" />
          <LapeNewInput name="name" label="Policy name" required />
        </Box>
        <Box>
          <SectionTitle title="What type of policy is it?" />
          <LapeRadioSelectInput
            name="category"
            label="Policy category"
            options={categoriesOptions.map(option => ({
              id: option.id,
              label: option.name,
              value: option,
            }))}
            searchable={false}
          />
        </Box>
        <Box>
          <SectionTitle title="Will this policy be paid or unpaid for employees?" />
          <LapeRadioSwitch<IdAndName<string>>
            name="paid"
            variant="horizontal"
            options={[
              {
                id: 'paid',
                label: 'Paid',
                description:
                  'Employees will be paid for time off taken under this policy.',
                value: { id: 'paid', name: 'Paid leave' },
              },
              {
                id: 'unpaid',
                label: 'Unpaid',
                description:
                  'Employees will not be paid for time off taken under this policy.',
                value: { id: 'unpaid', name: 'Unpaid leave' },
              },
            ]}
          />
        </Box>
        <Box>
          <SectionTitle title="Should this policy be tracked in days or hours?" />
          <LapeRadioSwitch<IdAndName<string>>
            name="unit"
            variant="horizontal"
            options={[
              {
                id: 'day',
                label: 'Days',
                description: 'The balance of time off should be measured in days.',
                value: { id: 'day', name: 'Day' },
              },
              {
                id: 'hour',
                label: 'Hours',
                description: 'The balance of time off should be measured in hours.',
                value: { id: 'hour', name: 'Hour' },
              },
            ]}
          />
        </Box>
        <Box>
          <SectionTitle title="Should employees get unlimited allowance or a limited amount of time off?" />
          <LapeRadioSwitch<IdAndName<string>>
            name="balance_type"
            variant="horizontal"
            options={[
              {
                id: 'unlimited',
                label: 'Unlimited',
                description: 'There is no limit to how much time off employees can take.',
                value: { id: 'unlimited', name: 'Unlimited' },
              },
              {
                id: 'limited',
                label: 'Limited',
                description: `There is a limit to how many ${unit} an employee can take.`,
                value: { id: 'limited', name: 'Limited' },
              },
            ]}
          />
        </Box>
        <HalfDaySwitch />
      </VStack>
      <NextTabButton
        onAfterSubmit={async () => {
          await refetchBasicStepInfo()
        }}
        nextTabPath={nextTabPath}
        onNavigateToNextTab={onNavigateToNextTab}
      />
    </>
  )
}
