import { FinalGradeInterface, PerformanceRating } from '@src/interfaces/performance'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { CommentInterface } from '@src/interfaces/comments'

export interface GrowthPlanRelationInterface extends EmployeeOptionInterface {
  latest_review_score_label: FinalGradeInterface
  current_growth_plan: CurrentGrowthPlanInterface | null
  quality_control: EmployeeOptionInterface | null
}

export interface GrowthPlanRelationStatsInterface {
  total: number
}

interface CurrentGrowthPlanInterface {
  id: number
  employee: EmployeeOptionInterface
  growth_plan: GrowthPlanInterface
}

export enum GrowthPlanDecision {
  Ongoing = 'ongoing',
  Completed = 'completed',
  Missed = 'missed',
}

export interface GrowthPlanInterface {
  id: number
  employee: EmployeeOptionInterface
  owner: EmployeeOptionInterface | null
  status: Statuses | null
  decision?: GrowthPlanDecision
  current_specialisation: IdAndName
  current_seniority: IdAndName
  current_specialisation_seniority_sublevel: IdAndName | null
  target_specialisation: IdAndName
  target_seniority: IdAndName
  description: string
  deadline: string
  actions: GrowthPlanActionInterface[]
  field_options: FieldOptions
  remind_to_nominate?: boolean
}

export interface GrowthPlanActionInterface {
  id: number
  description: string
  is_completed: boolean
  object_id: number | null
  content_type: GrowthPlanActionContentTypeInterface | null
  content_object: GrowthPlanActionContentObjectInterface | null
}

export interface GrowthPlanActionContentTypeInterface<T = 'skillset' | 'companyvalue'> {
  id: number
  app_label: string
  model: T
}

export interface GrowthPlanActionContentObjectInterface {
  id: number
  name: string
  avatar: string | null
}

export type GrowthPlanCommentId = 'growth_plan'

export interface GrowthPlanCommentInterface
  extends CommentInterface<GrowthPlanCommentId> {
  content_type: GrowthPlanActionContentTypeInterface<'growthplan'>
}

export interface GrowthPlanPerformanceMetricInterface {
  id: number
  name: string
  rating: PerformanceRating | null
  competency_level: PerformanceRating | null
  requirements?: string[]
}

export interface GrowthPlanPerformanceSummaryInterface {
  functional_skills: GrowthPlanPerformanceMetricInterface[]
  culture_values: GrowthPlanPerformanceMetricInterface[]
}
