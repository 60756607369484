import { EntityTypes } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { useMemo } from 'react'
import { Statuses } from '@src/interfaces'

interface Props {
  data: FilterByInterface[]
  isLoading: boolean
}

const statusFilter: FilterByInterface = {
  columnName: 'approval_status',
  nonResettable: true,
  filters: [
    { id: Statuses.pending, name: Statuses.pending },
    { id: Statuses.approved, name: Statuses.approved },
    { id: Statuses.requires_changes, name: Statuses.requires_changes },
  ],
}

export const useGoalsFilters = (
  entityType: EntityTypes,
  entityId: number,
  reviewCycle: ReviewCyclesInterface | null,
): Props => {
  const { filter: contentTypeFilter, isLoading: isContentTypeFilterLoading } =
    useGetGoalContentTypeFilter(entityType)

  const cycleFilter = useMemo<FilterByInterface | null>(
    () =>
      reviewCycle
        ? {
            columnName: 'cycle__id',
            filters: [{ id: reviewCycle.id, name: reviewCycle.name }],
            nonResettable: true,
          }
        : null,
    [reviewCycle?.id],
  )

  const objectIdFilter = useMemo<FilterByInterface>(
    () => ({
      columnName: 'object_id',
      filters: [{ id: entityId, name: '' }],
      nonResettable: true,
      nonInheritable: true,
    }),
    [entityId],
  )

  return useMemo<Props>(() => {
    return {
      isLoading: isContentTypeFilterLoading,
      data: contentTypeFilter
        ? [cycleFilter, statusFilter, contentTypeFilter, objectIdFilter].filter(Boolean)
        : [],
    }
  }, [isContentTypeFilterLoading, contentTypeFilter, cycleFilter, objectIdFilter])
}
