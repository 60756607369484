import React, { useState } from 'react'
import { isEmpty, lowerCase } from 'lodash'
import { Action, Flex, Placeholder } from '@revolut/ui-kit'

import Loader from '@src/components/CommonSC/Loader'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { TableNames } from '@src/constants/table'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { MAX_ITEMS_ALLOWED_FOR_ANALYSIS, ResultsInterface } from './common'
import { useHeatmapTable } from './useHeatmapTable'

export const Heatmap = ({
  itemsToAnalyse,
  surveyId,
  groupBy,
  heatmapFilters,
  isLoading,
}: ResultsInterface & { itemsToAnalyse: 'questions' | 'categories' }) => {
  const [validationError, setValidationError] = useState<string>()
  const [unknownError, setUnknownError] = useState<string>()

  const hasFilterValue = !isEmpty(heatmapFilters.tableParams)

  const { row: heatmapRow, table: heatmapTable } = useHeatmapTable({
    itemsToAnalyse,
    surveyId,
    groupBy: groupBy.id,
    groupByFilterValue: heatmapFilters.tableParams,
    onRefresh: () => {
      setValidationError(undefined)
      setUnknownError(undefined)
    },
    onError: e => {
      if (e.response?.status === 400) {
        setValidationError(getStringMessageFromError(e))
      } else {
        setUnknownError(getStringMessageFromError(e))
      }
    },
  })

  const isNewTable = useIsNewTable()

  if (isLoading || heatmapTable.loading) {
    return (
      <Flex height={238} alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    )
  }
  if (unknownError) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
        />
        <Placeholder.Title>Could not load heatmap data</Placeholder.Title>
        <Placeholder.Description>{unknownError}</Placeholder.Description>
      </Placeholder>
    )
  }
  if (validationError) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D221.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@3x.png',
          }}
        />
        <Placeholder.Title>Filter values need to be adjusted</Placeholder.Title>
        <Placeholder.Description>{validationError}</Placeholder.Description>
        <Placeholder.Actions>
          <Action onClick={heatmapFilters.clearAll}>Clear all filters</Action>
        </Placeholder.Actions>
      </Placeholder>
    )
  }
  return (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultQuestionsHeatmap}
      row={heatmapRow}
      emptyState={
        <EmptyTableRaw
          title={
            hasFilterValue
              ? 'No results for this survey or selected filters configuration'
              : `To see the results you should select up to ${MAX_ITEMS_ALLOWED_FOR_ANALYSIS} ${lowerCase(
                  groupBy.name,
                )} filter options`
          }
          action={
            hasFilterValue ? (
              <Action onClick={heatmapFilters.clearAll}>Clear all filters</Action>
            ) : undefined
          }
        />
      }
      hideCount={isNewTable}
      useWindowScroll
      forcedRowHeight="heatmap"
      {...heatmapTable}
    />
  )
}
