import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { ResultCategories } from './Categories'
import { ResultQuestions } from './Questions'
import { ResultsInterface } from './common'

export const TableContent = (props: ResultsInterface) => {
  return (
    <Switch>
      <Route
        exact
        path={[
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.QUESTIONS,
          ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS.QUESTIONS,
        ]}
      >
        <ResultQuestions {...props} />
      </Route>
      <Route
        exact
        path={[
          ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES,
          ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES,
        ]}
      >
        <ResultCategories {...props} />
      </Route>
    </Switch>
  )
}
