import React from 'react'
import { Spacer, VStack, Widget } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import Loader from '@components/CommonSC/Loader'
import { useGetEmployeeGrowthPlan } from '@src/features/GrowthPlans/hooks/useGetEmployeeGrowthPlan'
import { EmptyGrowthPlan } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/EmptyGrowthPlan'
import { GrowthPlan } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/GrowthPlan'
import { GrowthPlansQueryKeys, useGetGrowthPlans } from '@src/api/growthPlans'
import { Statuses } from '@src/interfaces'
import { ArchivedGrowthPlan } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/ArchivedGrowthPlan'

interface Props {
  employee: EmployeeInterface
}

export const PreviewGrowthPlan = ({ employee }: Props) => {
  const {
    data: growthPlan,
    isFetching: isGrowthPlanFetching,
    refetch: refetchCurrentGrowthPlan,
  } = useGetEmployeeGrowthPlan(employee.id)
  const { data: archivedGrowthPlans, refetch: refetchArchivedGrowthPlans } =
    useGetGrowthPlans({
      [GrowthPlansQueryKeys.EmployeeId]: employee.id,
      [GrowthPlansQueryKeys.Status]: [Statuses.archived],
    })

  const handleRefetch = () => {
    refetchCurrentGrowthPlan()
    refetchArchivedGrowthPlans()
  }

  if (isGrowthPlanFetching) {
    return (
      <Widget>
        <VStack height={300}>
          <Spacer />
          <Loader />
          <Spacer />
        </VStack>
      </Widget>
    )
  }

  return (
    <VStack space="s-16">
      {growthPlan ? (
        <GrowthPlan employee={employee} growthPlan={growthPlan} refetch={handleRefetch} />
      ) : (
        <EmptyGrowthPlan employee={employee} />
      )}
      {(archivedGrowthPlans?.results ?? []).map(archivedGrowthPlan => (
        <ArchivedGrowthPlan
          key={archivedGrowthPlan.id}
          employee={employee}
          growthPlan={archivedGrowthPlan}
        />
      ))}
    </VStack>
  )
}
