import React from 'react'
import { OverallProgressCircle } from './SummaryHeader/OverallProgressCircle'
import {
  Box,
  Flex,
  Separator,
  Text,
  ProgressCircle,
  VStack,
  Token,
  Highlights,
  Grid,
  Icon,
  Tooltip,
  useTooltip,
  HStack,
} from '@revolut/ui-kit'
import { css } from 'styled-components'
import { HeaderSummarySkeleton } from '@src/pages/Forms/DepartmentForm/Performance/SummaryHeader/HeaderSummarySkeleton'
import { formatPercentage } from '@src/utils/format'
import {
  PerformanceSummaryData,
  CycleType,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { getOverallProgressGrade } from '@src/pages/Forms/DepartmentForm/Performance/utils'

interface PerformanceSummaryProps {
  summaryStats?: PerformanceSummaryData
  cycleType?: CycleType
}
const ProgressCircleTextCss = css`
  font-size: ${Token.fontSize.emphasis3};
`

export const PerformanceSummaryHeader = ({
  summaryStats,
  cycleType,
}: PerformanceSummaryProps) => {
  const karmaTooltip = useTooltip()
  const bonusTooltip = useTooltip()

  if (!summaryStats) {
    return <HeaderSummarySkeleton cycleType={cycleType} />
  }
  const CIRCLE_RADIUS = 48
  const {
    display_name,
    overall_score,
    goal_progress,
    roadmap_progress,
    karma_percentage,
    estimated_bonus_impact,
    bad_karma,
    good_karma,
    total_karma,
    total_max_karma,
  } = summaryStats

  const getKarmaBonusImpactColor = () => {
    const num = estimated_bonus_impact?.percent
    if (num === undefined) {
      return Token.color.greyTone20
    }
    if (num === 0) {
      return Token.color.success
    }
    if (estimated_bonus_impact?.is_accrediated && num < 5) {
      return Token.color.foreground
    }
    if (estimated_bonus_impact?.is_accrediated && num < 10) {
      return Token.color.warning
    }
    return Token.color.danger
  }

  const karmaTooltipText = (
    <Box>
      <ul style={{ listStyleType: 'none' }}>
        <li>Total karma: {total_karma}</li>
        <li>
          Good karma: <span style={{ color: Token.color.success }}>{good_karma}</span>
        </li>
        <li>
          Bad karma: <span style={{ color: Token.color.danger }}>{bad_karma} </span>
        </li>
        <li>Max karma: {total_max_karma}</li>
      </ul>
    </Box>
  )

  const karmaBonusImpactTooltipText = (
    <Box>
      Karma Bonus Impact is estimated based on cumulative Karma score for the year with
      Default multiplier as "High". Final Bonus impact of Karma is published at the end of
      the year after after year-end adjustments
    </Box>
  )

  return (
    <>
      {summaryStats && (
        <Flex p="s-16" style={{ flexGrow: 1 }} gap="s-16" flexWrap="wrap">
          <OverallProgressCircle
            periodName={display_name}
            grade={getOverallProgressGrade(overall_score.percent * 100)}
            percent={overall_score.percent}
            cycleType={cycleType}
          />
          <VStack>
            <Text py="s-12" pl="s-12" variant="emphasis2" color={Token.color.greyTone50}>
              Performance Indicators
            </Text>

            <Grid>
              <Highlights>
                <Flex gap="s-16" p="s-16" alignItems="center">
                  <ProgressCircle
                    strokeWidth={4}
                    size={CIRCLE_RADIUS}
                    value={goal_progress.percent}
                    color={getPercentColor(goal_progress.percent * 100)}
                    bg={Token.color.greyTone5}
                  >
                    <ProgressCircle.Text
                      css={ProgressCircleTextCss}
                      color={getPercentColor(goal_progress.percent * 100)}
                    >
                      {formatPercentage(goal_progress.percent)}
                    </ProgressCircle.Text>
                  </ProgressCircle>
                  <Text variant="emphasis1">Goals</Text>
                </Flex>
                <Flex gap="s-16" p="s-16" alignItems="center">
                  <ProgressCircle
                    strokeWidth={4}
                    size={CIRCLE_RADIUS}
                    value={roadmap_progress.percent}
                    color={getPercentColor(roadmap_progress.percent * 100)}
                    bg={Token.color.greyTone5}
                  >
                    <ProgressCircle.Text
                      css={ProgressCircleTextCss}
                      color={getPercentColor(roadmap_progress.percent * 100)}
                    >
                      {formatPercentage(roadmap_progress.percent)}
                    </ProgressCircle.Text>
                  </ProgressCircle>
                  <Text variant="emphasis1">Roadmap</Text>
                </Flex>

                <Box height={60} display={{ all: 'none', lg: 'block' }}>
                  <Separator orientation="vertical" />
                </Box>

                <Flex gap="s-16" p="s-16" alignItems="center">
                  <ProgressCircle
                    strokeWidth={4}
                    size={CIRCLE_RADIUS}
                    value={karma_percentage.percent}
                    color={
                      karma_percentage.percent >= 0.5
                        ? Token.color.success
                        : Token.color.danger
                    }
                    bg={Token.color.greyTone5}
                  >
                    <ProgressCircle.Text
                      css={ProgressCircleTextCss}
                      color={
                        karma_percentage.percent >= 0.5
                          ? Token.color.success
                          : Token.color.danger
                      }
                    >
                      {formatPercentage(karma_percentage.percent)}
                    </ProgressCircle.Text>
                  </ProgressCircle>
                  <HStack space="s-4" align="center">
                    <Text variant="emphasis1">Karma</Text>
                    <Icon
                      name="InfoOutline"
                      size={16}
                      color={Token.color.greyTone50}
                      style={{ cursor: 'pointer' }}
                      {...karmaTooltip.getAnchorProps()}
                    />
                    <Tooltip {...karmaTooltip.getTargetProps()} width={240} p="s-12">
                      {karmaTooltipText}
                    </Tooltip>
                  </HStack>
                </Flex>

                {cycleType === CycleType.ReviewCycle || !estimated_bonus_impact ? null : (
                  <>
                    <Flex gap="s-16" p="s-16" alignItems="center">
                      <ProgressCircle
                        strokeWidth={4}
                        size={CIRCLE_RADIUS}
                        value={estimated_bonus_impact.percent}
                        color={getKarmaBonusImpactColor()}
                        bg={Token.color.greyTone5}
                      >
                        <ProgressCircle.Text
                          color={getKarmaBonusImpactColor()}
                          css={ProgressCircleTextCss}
                        >
                          {formatPercentage(estimated_bonus_impact.percent)}
                        </ProgressCircle.Text>
                      </ProgressCircle>
                      <HStack space="s-4" align="center">
                        <Text variant="emphasis1">Estimated Bonus Impact</Text>
                        <Icon
                          name="InfoOutline"
                          size={16}
                          color={Token.color.greyTone50}
                          style={{ cursor: 'pointer' }}
                          {...bonusTooltip.getAnchorProps()}
                        />
                        <Tooltip {...bonusTooltip.getTargetProps()} width={240} p="s-12">
                          {karmaBonusImpactTooltipText}
                        </Tooltip>
                      </HStack>
                    </Flex>
                  </>
                )}
              </Highlights>
            </Grid>
          </VStack>
        </Flex>
      )}
    </>
  )
}
