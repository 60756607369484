import React, { useState } from 'react'
import { GenericError } from '@src/features/Form/FormErrorGuard'
import { HiringStagesListSkeleton } from '@src/features/JobPostingFlow/HiringProcess/HiringStagesListSkeleton'
import { HiringStage } from '@src/features/JobPostingFlow/HiringProcess/HiringStage'
import { HiringStageSidebar } from '@src/features/JobPostingFlow/HiringProcess/HiringStageSidebar'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { useGetSpecialisation } from '@src/api/specialisations'

interface Props {
  specialisationId: number
}

export const HiringStagesList = ({ specialisationId }: Props) => {
  const [activeStage, setActiveStage] = useState<HiringProcessInterface | null>(null)

  const { data: specialisation, isLoading } = useGetSpecialisation(specialisationId)

  if (isLoading) {
    return <HiringStagesListSkeleton />
  }

  if (!specialisation) {
    return <GenericError />
  }

  return (
    <>
      {activeStage && (
        <HiringStageSidebar stage={activeStage} onClose={() => setActiveStage(null)} />
      )}

      {specialisation.hiring_process_rounds.map(stage => (
        <HiringStage stage={stage} key={stage.id} onClick={() => setActiveStage(stage)} />
      ))}
    </>
  )
}
