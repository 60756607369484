import React from 'react'
import {
  Icon,
  TextButton,
  Token,
  TransitionCollapse,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { GrowthPlanHeader } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/GrowthPlanHeader'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/ActionItemsWidget'
import { CommentsWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/Comments/Comments'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { getGrowthPlanCommentsAPI } from '@src/api/growthPlans'
import { GeneralInfoWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GeneralInfo/GeneralInfoWidget'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
}

export const ArchivedGrowthPlan = ({ employee, growthPlan }: Props) => {
  const commentsAPI = getGrowthPlanCommentsAPI(growthPlan.id)

  const [isExpanded, expandedToggler] = useToggle()

  return (
    <VStack space="s-16">
      <Widget p="s-16">
        <GrowthPlanHeader
          employee={employee}
          growthPlan={growthPlan}
          prefix={
            <TextButton onClick={expandedToggler.switch} color={Token.color.greyTone50}>
              <Icon name={isExpanded ? 'ChevronUp' : 'ChevronRight'} />
            </TextButton>
          }
        />
      </Widget>
      <TransitionCollapse in={isExpanded}>
        <TwoColumnsLayout
          left={
            <VStack space="s-16">
              {growthPlan.description && <GeneralInfoWidget growthPlan={growthPlan} />}
              {growthPlan.actions.length > 0 && (
                <ActionItemsWidget growthPlan={growthPlan} />
              )}
            </VStack>
          }
          right={
            <Widget px="s-16">
              <CommentsWidget api={commentsAPI} />
            </Widget>
          }
        />
      </TransitionCollapse>
    </VStack>
  )
}
