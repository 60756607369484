import { useEffect, useMemo, useState } from 'react'

import { OptionInterface } from '@src/interfaces/selectors'
import {
  HeatmapDataGroupByOptions,
  MAX_ITEMS_ALLOWED_FOR_ANALYSIS,
  UseHeatmapFiltersReturnType,
} from './common'

export const useHeatmapFilters = (
  groupBy: HeatmapDataGroupByOptions,
  groupByOptionItems: OptionInterface[],
): UseHeatmapFiltersReturnType => {
  const [defaultValue, setDefaultValue] = useState<
    Record<HeatmapDataGroupByOptions['id'], OptionInterface[]>
  >({})
  const [value, setValue] = useState<
    Record<HeatmapDataGroupByOptions['id'], OptionInterface[]>
  >({})

  useEffect(() => {
    if (groupBy && groupByOptionItems.length) {
      setDefaultValue({
        ...defaultValue,
        [groupBy.id]: groupByOptionItems.slice(0, MAX_ITEMS_ALLOWED_FOR_ANALYSIS),
      })
    }
  }, [groupBy, groupByOptionItems])

  const valueWithFallback = value[groupBy.id] || defaultValue[groupBy.id]

  const tableParams = useMemo(
    () => valueWithFallback?.map(item => item.id).join(','),
    [valueWithFallback],
  )

  return {
    value: valueWithFallback,
    setValue: newValue => setValue({ ...value, ...newValue }),
    tableParams,
    clearAll: () => {
      setValue({})
    },
  }
}
