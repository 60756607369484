export const HR_SERVICE_DESK_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/99'

export const HR_SERVICE_DESK_CHANGE_REQUEST_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/631/group/1293/create/24010'

export const CHANGE_REQUEST_PROCESS_LINK =
  'https://revolut.atlassian.net/wiki/spaces/REVOLUTERS/pages/2424472445/How+can+I+request+employee+changes+in+Revoluters'

export const PERFORMANCE_REVIEW_REQUEST_CHANGE_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/73/group/180/create/16762'

export const CONTACT_PERFORMANCE_TEAM_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/73'

export const PERFORMANCE_SERVICE_DESK_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/73/group/-1'

export const CONTACT_PEOPLE_DATA_TEAM_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/129'

export const MOVERS_PROCESS_LINK =
  'https://revolut.atlassian.net/wiki/spaces/ISSD/pages/2612989086/Internal+change+of+a+team+or+specialisation'

export const REVOLUTERS_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/137'

export const REVOLUTERS_BUG_REPORT =
  'https://revolut.atlassian.net/servicedesk/customer/portal/137/group/1056/create/13206'

export const DIVERSITY_DATA =
  'https://revolut.atlassian.net/wiki/spaces/PEOP/pages/1748505584/Employee+Diversity+Data'

export const TIME_OFF_SERVICE_DESK_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/99/group/295/create/12737'

export const REVOLUT_FEEDBACK_SERVICE_DESK_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/422/group/840/create/17029'

export const ENVIRONMENTAL_HEALTH_SAFETY_SERVICE_DESK_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/40/group/824'

export const SLACK_BAME_AT_REVOLUT = 'https://slack.com/app_redirect?channel=CSZ4UUPGX'

export const SLACK_PRIDE_AT_REVOLUT = 'https://slack.com/app_redirect?channel=CSTB7AAVD'

export const SLACK_PARENTS_CARERS_AT_REVOLUT =
  'https://slack.com/app_redirect?channel=CT95N6GH5'

export const WELLBEING_AND_MENTAL_HEALTH =
  'https://revolut.atlassian.net/wiki/spaces/GUIL/pages/2205324198/Wellbeing+and+Mental+Health+Voluntary+First+Aiders'

export const EMPLOYEE_ENGAGEMENT_SERVICE_DESK_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/422'

export const KPI_CALIBRATED_PERFORMANCE_VALUES_CONFLUENCE =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2051656163/KPI+Scores+Check+Review+Process#Roadmap-KPI-Calibration--'

export const REFERRAL_BONUSES =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1032028237/Referral+Bonuses'

export const EMPLOYEE_REFERRAL_PROGRAM_STANDARD_OPERATIONAL_PROCEDURE =
  'https://revolut.atlassian.net/wiki/spaces/RecDep/pages/3140747399/Employee+Referral+Program+Standard+Operational+Procedure'

export const MRT_FORM =
  'https://revolut.atlassian.net/servicedesk/customer/portal/168/create/17813'

export const BENCHMARK_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/168/group/413/create/18695'

export const LEARNING_AND_DEVELOPMENT =
  'https://revolut.atlassian.net/servicedesk/customer/portal/160'

export const CONFLICT_OF_INTERESTS =
  'https://backoffice.revolut.com/compliance/conflicts-of-interest'

export const PROMOTION_PROCESS =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2237825702/Promotion'

export const PROMOTION_ELIGIBILITY =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2137096356/Revolut+Promotion+Philosophy'

export const PROMOTION_MANAGERS_GUIDELINES =
  'https://revolut.atlassian.net/wiki/spaces/TD/pages/2691303350/Nomination+Submission+and+Promotion+Review+Guideline+for+LM+FM'

export const PROMOTION_JUSTIFICATION_EXAMPLES =
  'https://revolut.atlassian.net/wiki/spaces/TD/pages/2691303350/Nomination+Submission+and+Promotion+Review+Guideline+for+LM+FM#Hypothetical-Nomination-Examples-for-LMs%2FFMs'

export const LEADERSHIP_TABLE =
  'https://revolut.atlassian.net/wiki/spaces/KRJI/pages/2613161677/Leadership+Table'

export const PAYROLL_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/79'

export const PAYROLL_INTERNAL_REQUEST =
  'https://revolut.atlassian.net/servicedesk/customer/portal/79/group/631/create/15295'

export const RECRUITMENT_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/89'

export const BUDGET_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/168/group/1003/create/18927'

export const INTERNAL_MOVE_PROCESS =
  'https://revolut.atlassian.net/servicedesk/customer/portal/631/create/23971'

export const TIME_OFF_POLICY_PORTAL =
  'https://backoffice.revolut.com/policies/filters?draft=false&category=%22policies%22&search=%22leave%22'

export const TIME_OFF_USER_GUIDE =
  'https://revolut.atlassian.net/wiki/spaces/KRJI/pages/2493417580/Time+Off+in+Revoluters+-+Guide+for+Employees+Managers'

export const KPIS_GUIDE =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1464909259/Key+Performance+Indicators+KPI'

export const KPIS_FAQ = 'https://revolut.atlassian.net/l/cp/RxxwhicH'
export const PERFORMANCE_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/73/group/-1'

export const CREATING_SKILLS =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1238073890/Creating%2BSkills'

export const DEFINING_SKILLS = 'https://revolut.atlassian.net/l/cp/WqS13nt1'

export const DOCUMENT_LABEL_REQUEST_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/137/group/362/create/13105'

export const COMPLAINTS_SERVICE_DESK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/135/group/321/create/799'

export const LINKED_ACCOUNTS_INFO_LINK =
  'https://revolut.atlassian.net/wiki/spaces/KRJI/pages/2346363702/Linked+Accounts+in+Revoluters'

export const PERFORMANCE_REVIEW_GUIDE =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2211299700/Performance+Review'

export const COMPENSATION_MANAGEMENT_FAQ =
  'https://revolut.atlassian.net/wiki/spaces/PP/pages/2670989078/Compensation+Management+Module+-+Full+Guidance+for+HoDs+HR+BPs'

export const REVOLUT_FACEBOOK = 'https://www.facebook.com/revolutapp/'

export const PRIVACY_POLICY = 'https://www.revolut.com/privacy-policy/'

export const TERMS_AND_CONDITIONS =
  'https://www.revolut.com/legal/website-terms-and-conditions/'

export const TERMS_AND_CONDITIONS_COMMERCIAL =
  'https://cdn.revolut.com/website/assets/pdf/Revolut_People_Trial_T&Cs.pdf'

export const SHAREWORKS_LINK =
  'https://revolut.okta.com/home/shareworks/0oaik57u8norqzaoX357/44463'

export const SHAREWORKS_HOW_TO =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2630889685/Shareworks+how+to+guide'

export const FETCH_JIRA_ROADMAP =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1328941872/Fetching+Jira+roadmap+in+Revoluters'

export const ONE_TO_ONES_GUIDE =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2536080121/Conducting+LM+weekly+1+1s'

export const CREATE_ONE_TO_ONE_MEETING =
  'https://www.google.com/calendar/event?action=TEMPLATE&text=%5B1:1%5D'

export const KPI_FAQ_LINK = 'https://revolut.atlassian.net/l/cp/Hf6GCD11'

export const KPI_CHECKLIST_PLAYBOOK = 'https://revolut.atlassian.net/l/cp/LAJnafXf'

export const RESOLVING_FINDING_TICKETS =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1442185646/Resolving+Finding+Tickets'

export const EMPOLOYEE_CHANGE_REQUESTS =
  'https://revolut.atlassian.net/wiki/spaces/PP/pages/2424472445/How+can+I+request+employee+changes+in+Revoluters'

export const INFORMATION_SECURITY_POLICY =
  'https://backoffice.revolut.com/policies/document/385'

export const DATA_PROTECTION_POLICY_EU_UK =
  'https://backoffice.revolut.com/policies/document/97'

export const DATA_PROTECTION_POLICY_GLOBAL =
  'https://backoffice.revolut.com/policies/document/157'

export const EMPLOYEE_PRIVACY_NOTICE_GLOBAL =
  'https://backoffice.revolut.com/policies/document/163'

export const PIP_PLAYBOOK =
  'https://revolut.atlassian.net/wiki/spaces/PEOP/pages/1346339647/Performance+Improvement+Plan+PIP+-+Line+Manager+Guide'

export const LOOKER_DASHBOARD_DEPARTMENT_LINK =
  'https://revolut.cloud.looker.com/dashboards/people_leadership::staff_cost_dashboard?Department=Core'

export const PROBLEM_SOLVING_PLAYBOOK =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2472280434/Problem-solving+playbook'

export const GLASS_DOOR_URL =
  'https://www.glassdoor.co.uk/mz-survey/employer/collectReview_input.htm?i=1176471&j=true&y=&c=PAGE_INFOSITE_TOP'

export const REVOLUT_VOICES_CONFLUENCE =
  'https://revolut.atlassian.net/wiki/spaces/TD/pages/2842167370/Revolut+Voices'

export const REVIEWING_CULTURE_FIT =
  'https://revolut.atlassian.net/wiki/spaces/TD/pages/1575655776/Reviewing+Culture+Fit'

export const GRADE_RECOMMENDATION_GUIDELINES =
  'https://revolut.atlassian.net/l/cp/LUjRwPQV'

export const FEEDBACK_PROVIDING_GUIDELINES = 'https://revolut.atlassian.net/l/cp/DE0vZFdC'

export const KPI_SQL_QUERY_URL =
  'https://revolut.atlassian.net/wiki/spaces/BD/pages/2160966611/Rules+for+Helios+sessions+and+queries'
export const ADDING_ROADMAPS_TO_REVOLUTERS =
  'https://revolut.atlassian.net/wiki/spaces/PP/pages/2817950232/Adding+Roadmaps+to+Revoluters'

export const SLACK_APPS = 'https://api.slack.com/apps'

export const JIRA_HOW_TO =
  'https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/'

export const JIRA_TOKEN = 'https://id.atlassian.com/manage-profile/security/api-tokens'

export const DATA_PRIVACY_FOR_CANDIDATES =
  'https://www.revolut.com/legal/data-privacy-for-candidates/'

export const CHAT_BOT_HELP_SERVICE_DESK_CUSTOMER_PORTAL =
  'https://revolut.atlassian.net/servicedesk/customer/portal/631'

export const CHAT_BOT_HELP_SERVICE_DESK_CUSTOMER_PORTAL_GROUP_PRE_FILLED = `${CHAT_BOT_HELP_SERVICE_DESK_CUSTOMER_PORTAL}/group/1293/create/24025`

export const PROGRESSION_POLICY_PLAYBOOK_LINK =
  'https://revolut.atlassian.net/wiki/spaces/TD/pages/2598866690/How+are+promotions+and+progressions+assessed+when+Promotion+Review+and+the+Performance+Review+overlap'

export const DEFINING_ROLE_MISSION =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1271432279/Defining+role+missions'

export const DEFINING_ROLE_GOALS =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1245315384/Defining+Role+Goals+and+Objectives'

export const REVOLUT_CAREERS = 'https://www.revolut.com/careers/'

export const PERFORMANCE_REVIEW_TOPICS = 'https://revolut.atlassian.net/l/cp/E0QDR7m0'
export const REVIEW_DELIVERABLES =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/1575586897/Review+deliverables'

export const MY_LINKEDIN_PROFILE = 'https://www.linkedin.com/in/me/'

export const COOKIES_POLICY = 'https://www.revolut.com/en-IN/legal/cookies-policy/'

export const RECRUITMENT_GMAIL_INTEGRATION_PLAYBOOK =
  'https://assets.revolut.com/revoluters/assets/Recruitment_Gmail_Google_Calendar_Integration_Guide.pdf'

export const JIRA_TICKET_BASE_URL = 'https://revolut.atlassian.net/browse/'

export const CDN_BUCKET = 'https://cdn.revolut.com/revoluters/assets'

export const LINKEDIN_INTEGRATION_INSTRUCTIONS =
  'https://www.linkedin.com/help/linkedin/answer/a415420/associate-your-linkedin-company-id-with-the-linkedin-job-board-faqs?lang=en'

export const LINKEDIN_TC = 'https://www.linkedin.com/legal/jobs-terms-conditions'

export const HELP_PERF_REVIEW_PLAYBOOK = 'https://revolut.atlassian.net/wiki/x/IQGRVw'

export const HELP_DELIVERABLES_LINK = 'https://revolut.atlassian.net/wiki/x/UYzpXQ'

export const HELP_SKILLS_LINK = 'https://revolut.atlassian.net/wiki/x/5hPqXQ'

export const HELP_VALUES_LINK = 'https://revolut.atlassian.net/wiki/x/YJnqXQ'

export const PROBATION_PLAYBOOK = 'https://revolut.atlassian.net/l/cp/o7uQyXtQ'

export const GOOGLE_CALENDAR = 'https://calendar.google.com/calendar/u/0/r/week'

export const MICROSOFT_CALENDAR = 'https://outlook.office.com/calendar/view/week'

export const CONFLUENCE_HOW_TO_REMOVE_QUATERLY_TARGET =
  'https://revolut.atlassian.net/wiki/spaces/CORE/pages/3798075539/How+can+I+remove+a+Quarter+s+Target+within+a+Goal'

export const GOOGLE_WORKSPACE_ADMIN_GUIDE = 'https://support.google.com/a/answer/172176'

export const REVOLUT_PEOPLE_DOCUMENTATION = 'https://revolutpeople.com/resources/'

export const REVOLUT_PEOPLE_OKTA_DOCUMENTATION =
  'https://revolutpeople.com/resources/docs/module/system-admin/integrations/okta-sso'

export const TABLEAU_LOGIN = 'https://sso.online.tableau.com/public/idp/SSO'

export const CLICKUP_LOGIN = 'https://app.clickup.com/'

export const PERSONAL_KPI_SETTING_AND_APPROVAL_INFO =
  'https://revolut.atlassian.net/wiki/spaces/REV/pages/2434467804/Personal+KPI+Setting+and+Approval'

export const INDEED = 'https://indeed.com'

export const JIRA_EMAIL_VISIBILITY_LINK =
  'https://support.atlassian.com/confluence-cloud/docs/configure-user-email-visibility/'

export const CONFLUENCE_CONTRACT_TYPES_DOCUMENTATION =
  'https://revolut.atlassian.net/wiki/spaces/REVOLUTERS/pages/2237895233/Contract+types+in+Revoluters'

export const CXLABS_BASE_URL = 'https://cxlabs.revolut.com/dashboard/departments'

export const MEETINGS_EXTENSION_INTERNAL =
  'https://chromewebstore.google.com/detail/revolut-people-meetings/epeiijalnefjildjmbmmoggkgijbmdkk'
