import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import {
  useCanApproveGrowthPlan,
  useCanChangeGrowthPlan,
  useCanDeleteGrowthPlan,
  useCanRejectGrowthPlan,
} from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { useIsPromotionNominationEnabled } from '@src/features/GrowthPlans/hooks/useIsPromotionNominationEnabled'
import { growthPlanModel } from '@src/features/GrowthPlans/GrowthPlan/models/growthPlanModel'
import { CompleteAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/CompleteAction'
import { MissAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/MissAction'
import { ApproveAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/ApproveAction'
import { EditAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/EditAction'
import { RejectAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/RejectAction'
import { ArchiveAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/ArchiveAction'
import { NominateAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/NominateAction'
import { RemindToNominateAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/RemindToNominateAction'
import { DeleteAction } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/DeleteAction'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  refetch: VoidFunction
  refetchApproval: () => Promise<void>
}

export const GrowthPlanActions = ({
  employee,
  growthPlan,
  refetch,
  refetchApproval,
}: Props) => {
  const canChangeGrowthPlan = useCanChangeGrowthPlan(growthPlan)
  const canApproveGrowthPlan = useCanApproveGrowthPlan(growthPlan)
  const canRejectGrowthPlan = useCanRejectGrowthPlan(growthPlan)
  const canDeleteGrowthPlan = useCanDeleteGrowthPlan(growthPlan)
  const isPromotionNominationEnabled = useIsPromotionNominationEnabled()

  const handleOnArchive = () => {
    refetch()
  }

  const handleOnDelete = () => {
    refetch()
  }

  const handleOnApproval = () => {
    refetchApproval()
  }

  return (
    <>
      {canChangeGrowthPlan && growthPlanModel.isReadyForCompletion(growthPlan) && (
        <>
          <MissAction growthPlan={growthPlan} />
          <CompleteAction growthPlan={growthPlan} />
        </>
      )}
      {canRejectGrowthPlan && growthPlanModel.isReadyForApproval(growthPlan) && (
        <RejectAction growthPlan={growthPlan} onComplete={handleOnApproval} />
      )}
      {canApproveGrowthPlan && growthPlanModel.isReadyForApproval(growthPlan) && (
        <ApproveAction growthPlan={growthPlan} onComplete={handleOnApproval} />
      )}
      {canChangeGrowthPlan &&
        growthPlanModel.isApproved(growthPlan) &&
        growthPlanModel.isCompleted(growthPlan) && (
          <>
            {isPromotionNominationEnabled && <NominateAction employee={employee} />}
            {!isPromotionNominationEnabled &&
              !growthPlanModel.hasNominationReminder(growthPlan) && (
                <RemindToNominateAction growthPlan={growthPlan} />
              )}
          </>
        )}
      {canChangeGrowthPlan && !growthPlanModel.isDraft(growthPlan) && (
        <ArchiveAction growthPlan={growthPlan} onComplete={handleOnArchive} />
      )}
      {canChangeGrowthPlan && growthPlanModel.isEditable(growthPlan) && (
        <EditAction employee={employee} growthPlan={growthPlan} />
      )}
      {canDeleteGrowthPlan && growthPlanModel.isDraft(growthPlan) && (
        <DeleteAction growthPlan={growthPlan} onComplete={handleOnDelete} />
      )}
    </>
  )
}
