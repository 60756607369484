import React from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import { FormattedMessage } from 'react-intl'
import Form from '@src/features/Form/Form'
import { interviewAvailabilityPreferencesFormRequests } from '@src/api/interviewAvailability'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { Button, Side, VStack } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewAvailabilityPreferencesInterface } from '@src/interfaces/interviewAvailability'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { RequiresFeatureFlagWrapper } from '@components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'
import { FeatureFlags } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'

type InterviewAvailabilityFormProps = {
  onClose: () => void
  onSuccess: () => void
}

const InterviewAvailabilityForm = ({
  onClose,
  onSuccess,
}: InterviewAvailabilityFormProps) => {
  const { values } = useLapeContext<InterviewAvailabilityPreferencesInterface>()
  return (
    <>
      <VStack gap="s-16">
        <LapeNewInput
          description={
            <FormattedMessage
              id="recruitment.interviewAvailability.availabilityPreferences.dailyLimit.description"
              defaultMessage="Maximum number of interviews per day"
            />
          }
          hideOptional
          label="Daily limit"
          name="max_interviews_per_day"
          type="number"
          onAfterChange={val => {
            if (!val) {
              values.max_interviews_per_day = null
            }
          }}
        />
        <LapeNewInput
          description={
            <FormattedMessage
              id="recruitment.interviewAvailability.availabilityPreferences.weeklyLimit.description"
              defaultMessage="Maximum number of interviews per week"
            />
          }
          hideOptional
          label="Weekly limit"
          name="max_interviews_per_week"
          type="number"
          onAfterChange={val => {
            if (!val) {
              values.max_interviews_per_week = null
            }
          }}
        />

        <RequiresFeatureFlagWrapper flags={[FeatureFlags.CombinedDynamicSlots]}>
          <LapeNewSwitch
            name="use_dynamic_slots"
            itemTypeProps={{
              title: 'Define placeholder slots in my calendar',
              description:
                'Allow me to schedule specific placeholder time-slots for interviews instead of using any available free time',
            }}
            checked={!values.use_dynamic_slots}
            onChange={val => {
              values.use_dynamic_slots = !val.target.checked
            }}
          />
        </RequiresFeatureFlagWrapper>
      </VStack>
      <Side.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            onClose()
          }}
        >
          <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
        </Button>
        <NewSaveButtonWithPopup
          hideWhenNoChanges
          successText="Availability preferences saved successfully"
          onAfterSubmit={() => {
            onSuccess()
            onClose()
          }}
        />
      </Side.Actions>
    </>
  )
}

type InterviewAvailabilityPreferencesSidebarProps = InterviewAvailabilityFormProps & {
  employeeId: string | number
}

export const InterviewAvailabilityPreferencesSidebar = ({
  employeeId,
  onClose,
  onSuccess,
}: InterviewAvailabilityPreferencesSidebarProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const combinedDynamicSlotsEnabled = featureFlags.includes(
    FeatureFlags.CombinedDynamicSlots,
  )
  return (
    <SideBar
      useLayout
      isOpen
      title={
        <FormattedMessage
          id="recruitment.interviewAvailability.availabilityPreferences.title"
          defaultMessage="Availability preferences"
        />
      }
      subtitle={
        combinedDynamicSlotsEnabled ? (
          <FormattedMessage
            id="recruitment.interviewAvailability.availabilityPreferences.subtitleCombinedSlots"
            defaultMessage="By default, interviews can be booked in any available free time slot in your calendar. You can choose the maximum number of interviews you want to conduct in a given day or week."
          />
        ) : (
          <FormattedMessage
            id="recruitment.interviewAvailability.availabilityPreferences.subtitle"
            defaultMessage="You can set up Maximum number of interviews you want to conduct in a day and in a week to manage workload."
          />
        )
      }
      variant="wide"
      onClose={onClose}
    >
      <Form api={interviewAvailabilityPreferencesFormRequests(employeeId)} isExistingForm>
        <InterviewAvailabilityForm onClose={onClose} onSuccess={onSuccess} />
      </Form>
    </SideBar>
  )
}
