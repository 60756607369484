import React, { useEffect, useState } from 'react'
import { isNumber } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { FilterButtonSkeleton, Highlights, HStack, TabBar, Token } from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import { useGetGroupByOptionItems, useGetSurveyAnalytics } from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'
import {
  overallScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import { useSurveyRounds } from '@src/apps/People/Engagement/Results/hooks'
import Loader from '@src/components/CommonSC/Loader'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { TableBodySkeleton } from '@src/components/Skeletons/TableBodySkeleton'
import Stat from '@src/components/Stat/Stat'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { IdAndName } from '@src/interfaces'
import { formatPercentage } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName, toLabeledIdAndName } from '@src/utils/toIdAndName'
import { isHubAppPath } from '../../../helpers/isHubApp'
import { HeatmapDataGroupByOptions, ViewMode } from './common'
import { TableContent } from './TableContent'
import { useHeatmapFilters } from './useHeatmapFilters'

export const ResultsTab = ({ survey }: { survey: IdAndName }) => {
  const { state } = useLocation<{ roundId?: number }>()
  const { type: tableType = 'categories' } =
    useParams<{ type: 'categories' | 'questions' }>()

  const { data: groupByOptions, isLoading: isLoadingGroupByOptions } =
    useGetSelectors<HeatmapDataGroupByOptions>(
      selectorKeys.engagement_analytics_heatmap_groups,
    )
  const [selectedRound, setSelectedRound] = useState<IdAndName>()
  const [hasResults, setHasResults] = useState(true)
  const [viewMode, setViewMode] = useState<ViewMode>('table')
  const [groupBy, setGroupBy] = useState<HeatmapDataGroupByOptions>(
    groupByOptions?.[0] || toIdAndName('role', true),
  )

  const { data: groupByOptionItemsData, isLoading: isLoadingGroupByOptionItems } =
    useGetGroupByOptionItems(survey.id, groupBy?.id)
  const groupByOptionItems = groupByOptionItemsData?.options || []

  const heatmapFilters = useHeatmapFilters(groupBy, groupByOptionItems)
  const { rounds: roundsOptions, asyncState: surveyRoundsAsyncState } = useSurveyRounds(
    survey.id,
  )

  const { data: surveyAnalytics, isLoading: isLoadingSurveryAnalytics } =
    useGetSurveyAnalytics(survey.id)

  useEffect(() => {
    if (surveyRoundsAsyncState === 'ready') {
      if (roundsOptions.length) {
        let initRound = roundsOptions.at(0)
        if (state?.roundId) {
          const preselectedRound = roundsOptions.find(
            option => option.id === state.roundId,
          )
          if (preselectedRound) {
            initRound = preselectedRound
          }
        }
        setSelectedRound(initRound)
      } else {
        setHasResults(false)
      }
    }
  }, [surveyRoundsAsyncState])

  if (surveyRoundsAsyncState === 'pending') {
    return <Loader />
  }
  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Highlights>
          <Stat
            color={overallScoreToColor(surveyAnalytics?.average_score)}
            val={
              isLoadingSurveryAnalytics
                ? undefined
                : surveyAnalytics?.average_score || 'N/A'
            }
            label="Average score"
          />
          <Stat
            val={
              isLoadingSurveryAnalytics
                ? undefined
                : surveyAnalytics?.audience_size || 'N/A'
            }
            label="Audience size"
            tooltip="This is the number of active employees who were requested to complete the survey"
          />
          <Stat
            val={
              isLoadingSurveryAnalytics
                ? undefined
                : formatPercentage(surveyAnalytics?.response_rate || null, 2)
            }
            label="Participation"
            tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
            color={
              !surveyAnalytics?.response_rate
                ? Token.color.foreground
                : participationRateToColor(
                    Math.floor(
                      isNumber(surveyAnalytics?.response_rate)
                        ? surveyAnalytics.response_rate * 100
                        : 0,
                    ),
                  )
            }
          />
        </Highlights>
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <Table.Widget.MoreBar>
          <TabBar
            variant="segmented fit"
            mx="auto"
            value={viewMode}
            onChange={value => {
              setViewMode(value || 'table')
            }}
          >
            <TabBar.Item
              useIcon="AvatarGrid"
              to="heatmap"
              aria-label="show heatmap view"
            />
            <TabBar.Item useIcon="16/List" to="table" aria-label="show list view" />
          </TabBar>
          {viewMode === 'heatmap' && (
            <>
              {isLoadingGroupByOptionItems ? (
                <FilterButtonSkeleton />
              ) : (
                <FilterButtonCheckboxSelect
                  searchable
                  label={groupBy.name}
                  options={groupByOptionItems}
                  value={heatmapFilters.value}
                  onChange={newValue => {
                    if (groupBy && newValue) {
                      heatmapFilters.setValue({ [groupBy.id]: newValue })
                    } else {
                      heatmapFilters.setValue({ [groupBy.id]: [] })
                    }
                  }}
                />
              )}
            </>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <HStack space="s-8">
          <RadioSelectInput
            inputProps={{ width: 240 }}
            label="Analyse"
            searchable={false}
            value={toIdAndName(tableType)}
            options={['categories' as const, 'questions' as const].map(
              toLabeledIdAndName,
            )}
            onChange={value => {
              if (value) {
                const isHubApp = isHubAppPath()
                const resultsBaseRoute =
                  ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.SURVEY_RESULTS
                const subRoute = value.id === 'categories' ? 'CATEGORIES' : 'QUESTIONS'

                navigateReplace(
                  pathToUrl(resultsBaseRoute[subRoute], {
                    id: survey.id,
                    type: value.id,
                  }),
                )
              }
            }}
          />
          {viewMode === 'heatmap' && (
            <RadioSelectInput
              inputProps={{ width: 240 }}
              label="Group by"
              searchable={false}
              loading={isLoadingGroupByOptions}
              value={groupBy}
              selector={selectorKeys.engagement_analytics_heatmap_groups}
              onChange={value => {
                if (value?.id) {
                  setGroupBy(value)
                }
              }}
            />
          )}
        </HStack>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        {!selectedRound ? (
          <TableBodySkeleton rowHeight="large" />
        ) : hasResults ? (
          <TableContent
            viewMode={viewMode}
            selectedRound={selectedRound}
            surveyId={survey.id}
            groupBy={groupBy}
            heatmapFilters={heatmapFilters}
            isLoading={
              isLoadingGroupByOptions ||
              isLoadingGroupByOptionItems ||
              surveyRoundsAsyncState !== 'ready'
            }
          />
        ) : (
          <EmptyTableRaw
            title="This survey does not have any rounds"
            description="Start the survey to get some results"
            imageId="3D018"
          />
        )}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
