import React from 'react'
import { Box, Token } from '@revolut/ui-kit'

import {
  getEngagementResultsTableRequests,
  getSurveyAdminResultQuestionsTableRequests,
} from '@src/api/engagement'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsAverageDistributionColumn,
  engagementResultsAverageScoreColumn,
  engagementResultsQuestionCategoryNameColumn,
  engagementResultsQuestionNameColumn,
  engagementResultsQuestionTypeColumn,
  engagementResultsTrendAverageScoreColumn,
} from '@src/constants/columns/engagementResults'
import { TableNames } from '@src/constants/table'
import {
  EngagementResultInterface,
  EngagementResultsEntities,
} from '@src/interfaces/engagement'
import {
  getResultsInitialFilterBy,
  ResultsInterface,
  useApplySurveyRoundFilter,
} from './common'
import { Heatmap } from './Heatmap'

const trendColumnTooltip = (
  <Box color={Token.color.background} p="s-8" minWidth={250}>
    This indicates how much the score for this question has increased or decreased since
    the last time it was asked to employees
  </Box>
)

const row = {
  cells: [
    { ...engagementResultsQuestionNameColumn, width: 350 },
    { ...engagementResultsQuestionCategoryNameColumn, width: 100 },
    { ...engagementResultsQuestionTypeColumn, width: 120 },
    { ...engagementResultsAverageScoreColumn, width: 95 },
    { ...engagementResultsAverageDistributionColumn, width: 300 },
    {
      ...engagementResultsTrendAverageScoreColumn,
      width: 95,
      headerTooltip: trendColumnTooltip,
    },
  ],
}

export const ResultQuestions = ({
  viewMode,
  selectedRound,
  surveyId,
  entity,
  groupBy,
  heatmapFilters,
  isLoading,
}: ResultsInterface) => {
  const isNewTable = useIsNewTable()
  const table = useTable<EngagementResultInterface>(
    entity
      ? getEngagementResultsTableRequests(
          entity.id,
          surveyId,
          entity.type,
          EngagementResultsEntities.Questions,
        )
      : getSurveyAdminResultQuestionsTableRequests({ surveyId }),
    getResultsInitialFilterBy(String(selectedRound.id)),
  )
  useApplySurveyRoundFilter(table, String(selectedRound.id))

  if (viewMode === 'heatmap') {
    return (
      <Heatmap
        itemsToAnalyse="questions"
        surveyId={surveyId}
        groupBy={groupBy}
        heatmapFilters={heatmapFilters}
        viewMode={viewMode}
        selectedRound={selectedRound}
        isLoading={isLoading}
      />
    )
  }
  return (
    <>
      <AdjustableTable
        name={TableNames.EngagementSurveysResultQuestions}
        row={row}
        emptyState={<EmptyTableRaw title="This survey has no results yet" />}
        hideCount={isNewTable}
        useWindowScroll
        {...table}
      />
    </>
  )
}
