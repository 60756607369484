import React from 'react'
import { Avatar, Caption, Flex, Icon, IconName, Item, Token } from '@revolut/ui-kit'
import { GrowthPlanPerformanceMetricInterface } from '@src/interfaces/growthPlans'
import { getComparedRatingsProps } from '@src/constants/columns/performanceSummary'

interface Props {
  value: GrowthPlanPerformanceMetricInterface
  iconName: IconName
  onClick: (metric: GrowthPlanPerformanceMetricInterface) => void
}

export const MetricItem = ({ value, iconName, onClick }: Props) => {
  const { name, rating, competency_level } = value
  const ratingProps = getComparedRatingsProps(rating, competency_level)

  return (
    <Item use="label" onClick={() => onClick(value)}>
      <Item.Avatar>
        <Avatar useIcon={iconName} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{name}</Item.Title>
      </Item.Content>
      <Item.Side>
        <Item.Value height="100%" mr="s-8">
          <Flex height="100%" alignItems="center" justifyContent="center">
            {rating ? (
              <Icon size={20} name={ratingProps.icon} color={ratingProps.iconColor} />
            ) : (
              <Caption color={Token.color.greyTone50}>N/A</Caption>
            )}
          </Flex>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
