import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  StatusPopup,
  Subheader,
  Text,
  Tooltip,
  VStack,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import {
  DetailsSidebars,
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import {
  JobPostingInterface,
  JobPostingSpecialisationInterface,
  LocationSource,
} from '@src/interfaces/jobPosting'
import { LocationInterface, RequisitionInterface } from '@src/interfaces/requisitions'
import { PageBody } from '@src/components/Page/PageBody'
import { selectorKeys } from '@src/constants/api'
import {
  connectRequisitions,
  requisitionsRequests,
  useGetHiringEnabledLocations,
  useGetJobPostingRequisitions,
} from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useLocation, useParams } from 'react-router-dom'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import { PageActions } from '@src/components/Page/PageActions'
import { getBackUrl } from '@src/features/JobPostingFlow/utils'
import { navigateTo } from '@src/actions/RouterActions'
import PostingCompensationBand from '@src/components/LocationCompensationBand/PostingCompensationBand'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useGetPostingCompensationBands } from '@src/api/benchmarks'
import ManualCompensationBandForm from '@src/pages/Forms/JobPosting/Components/ManualCompensationBandForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ConnectedRequisitions } from '@src/features/JobPostingFlow/Details/ConnectedRequistions'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { ConnectRequisitionsSidebar } from '@src/features/JobPostingFlow/Details/ConnectRequisitionsSidebar'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import uniqBy from 'lodash/uniqBy'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { CreateNewSpecialisationSidebar } from '@src/features/JobPostingFlow/Details/CreateNewSpecialisationSidebar'
import { Description } from '@src/features/JobPostingFlow/Details/Description'

const locationsToLocationOptions = (locations?: LocationInterface[]) => {
  return (locations || []).map(location => ({
    label: location.name,
    value: location,
  }))
}

type HiringLocationsProps = {
  requisitionLocations?: LocationInterface[]
}

const HiringLocations = ({ requisitionLocations }: HiringLocationsProps) => {
  const { values, errors } = useLapeContext<JobPostingInterface>()
  const { data: enabledLocationsData } = useGetHiringEnabledLocations()
  const locationOptions = locationsToLocationOptions(enabledLocationsData)
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableAutomaticCompensationBands =
    jobPostingSettings?.enable_automatic_compensation_ranges
  const selectedLocationIds = (values?.locations || []).map(
    ({ id: locationId }) => locationId,
  )
  const mandatoryCompensationLocations = (
    jobPostingSettings?.mandatory_compensation_locations || []
  )
    .map(({ id: locationId }) => locationId)
    .filter(locationId => selectedLocationIds?.includes(locationId))
  const { data: compensationBands, error: compensationBandsError } =
    useGetPostingCompensationBands(
      values.id,
      values.locations
        ?.filter(
          item =>
            item.posting_compensation_enabled ||
            mandatoryCompensationLocations.includes(item.id),
        )
        ?.map(item => item.id),
    )

  return (
    <VStack gap="s-16">
      <Checkbox
        checked={values.location_source === LocationSource.requisition}
        onChange={event => {
          const fromRequisitions = event.currentTarget.checked
          if (fromRequisitions) {
            values.location_source = LocationSource.requisition
            values.locations = undefined
          } else {
            values.location_source = LocationSource.manual
          }
        }}
      >
        <Text>Autofill locations from Requisitions</Text>
      </Checkbox>
      {values.location_source === LocationSource.requisition ? (
        <NewMultiSelect
          name="locations"
          placeholder="Locations"
          required
          options={locationOptions}
          value={locationsToLocationOptions(requisitionLocations)}
          disabled
        />
      ) : (
        <>
          <LapeNewMultiSelect<LocationInterface>
            name="locations"
            placeholder="Locations"
            required
            options={locationOptions}
            onAfterChange={locationValues => {
              const entries = Object.entries(values.salary_bands_by_location || {})
              const locationKeys = (locationValues || []).map(({ label }) => label)
              const keepEntries = entries.filter(([key]) => locationKeys.includes(key))
              if (keepEntries.length) {
                values.salary_bands_by_location = Object.fromEntries(keepEntries)
              }
            }}
          />
          <PostingCompensationBand bands={compensationBands} />
        </>
      )}
      {!enableAutomaticCompensationBands && !!mandatoryCompensationLocations.length && (
        <>
          {values.location_source !== LocationSource.manual && (
            <PostingCompensationBand bands={compensationBands} />
          )}
          <ManualCompensationBandForm
            locationIds={mandatoryCompensationLocations}
            values={values.salary_bands_by_location}
            errors={errors.salary_bands_by_location}
            onChange={salaryBandsByLocation => {
              values.salary_bands_by_location = values.salary_bands_by_location
                ? {
                    ...values.salary_bands_by_location,
                    ...salaryBandsByLocation,
                  }
                : salaryBandsByLocation
            }}
          />
        </>
      )}
      {compensationBandsError && (
        <ActionWidget
          title="Could not show compensation bands"
          text={getStringMessageFromError(compensationBandsError)}
        />
      )}
    </VStack>
  )
}

type DetailsProps = {
  onAfterSubmit: () => void
}

export const useSidebars = () => {
  const [state, setState] = useState<DetailsSidebars>()
  return {
    sidebar: state,
    toggleSidebar: (sidebar: DetailsSidebars) => {
      setState(sidebar === state ? undefined : sidebar)
    },
    closeSidebar: () => {
      setState(undefined)
    },
  }
}

export const useConnectedRequisitions = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const [loading, setLoading] = useState(false)
  const { data, isLoading } = useGetJobPostingRequisitions(values.id)
  const [connectedRequisitions, setConnectedRequisitions] =
    useState<RequisitionInterface[]>()
  if (!connectedRequisitions && data?.results?.length) {
    setConnectedRequisitions(data.results)
  }
  const showStatusPopup = useShowStatusPopup()
  const fetchLocationStateRequisition = async () => {
    if (locationState?.requisitionId) {
      setLoading(true)
      try {
        const res = await requisitionsRequests.getItem(locationState.requisitionId)
        if (res.data) {
          setConnectedRequisitions([...(connectedRequisitions ?? []), res.data])
        }
      } catch (e) {
        showStatusPopup({
          title: 'There was a error fetching requisition',
          status: 'error',
          description: getStringMessageFromError(e),
        })
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    if (!values.id) {
      fetchLocationStateRequisition()
    }
  }, [])
  return {
    connectedRequisitions: connectedRequisitions || [],
    loadingConnectedRequisitions: loading || isLoading,
    setConnectedRequisitions,
  }
}

export const Details = ({ onAfterSubmit }: DetailsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const {
    connectedRequisitions,
    loadingConnectedRequisitions,
    setConnectedRequisitions,
  } = useConnectedRequisitions()
  const tooltip = useTooltip()
  const statusPopup = useStatusPopup()
  const { sidebar, toggleSidebar, closeSidebar } = useSidebars()
  const handleRequisitionConnectionError = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Cannot connect job posting to requisition(s)
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button elevated onClick={statusPopup.hide}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleConnectedRequisitions = (newRequisitions: RequisitionInterface[]) => {
    const [first] = newRequisitions
    if (!values.recruiter && first?.recruiter) {
      values.recruiter = first.recruiter
    }
    setConnectedRequisitions(newRequisitions)
  }
  return (
    <>
      <PageBody>
        <VStack gap="s-16">
          <Box>
            <Subheader>
              <Subheader.Title>Job post name</Subheader.Title>
            </Subheader>
            <LapeNewInput
              name="name"
              label="Job posting title"
              message="This is job posting title candidates will see on the career website (Specialisation name by default)"
              required
            />
          </Box>
          <Box>
            <Subheader>
              <Subheader.Title>Specialisation</Subheader.Title>
            </Subheader>
            <Box {...tooltip.getAnchorProps()}>
              <LapeRadioSelectInput<JobPostingSpecialisationInterface>
                key={values.specialisation?.id}
                showCreateNewButton={!connectedRequisitions.length}
                name="specialisation"
                label="Specialisation"
                selector={selectorKeys.specialisations}
                onChange={val => {
                  if (!val) {
                    return
                  }
                  if (typeof val.id === 'string' && val.id === createNewKey) {
                    toggleSidebar('createNewSpecialisation')
                  } else if (val) {
                    values.specialisation = {
                      id: Number(val.id),
                      name: val.name,
                      role_id: val.role_id,
                      status: val.status,
                    }
                    if (values.specialisation?.name && !values.name) {
                      values.name = values.specialisation.name
                    }
                    if (values.hiring_process) {
                      values.hiring_process = undefined
                    }
                  }
                }}
                // Once a Job Posting is created, the “Specialisation” field
                // should not be editable in order to prevent situations
                // when candidate’s job posting and candidate specialisation’s
                // hiring process are different from each other.
                disabled={!!values.id || !!sidebar}
              />
              {!!values.id && (
                <Tooltip {...tooltip.getTargetProps()}>
                  It's not possible to change job posting's specialisation after the job
                  posting is created
                </Tooltip>
              )}
            </Box>
          </Box>
          <ConnectedRequisitions
            requisitions={connectedRequisitions}
            loading={loadingConnectedRequisitions}
            onConnectRequisitions={() => {
              toggleSidebar('connectRequisitions')
            }}
            onRemoveConnectedRequisition={id => {
              setConnectedRequisitions(
                connectedRequisitions.filter(requisition => requisition.id !== id),
              )
            }}
          />
          <Box>
            <Subheader>
              <Subheader.Title>Locations</Subheader.Title>
            </Subheader>
            <HiringLocations
              requisitionLocations={uniqBy(
                (connectedRequisitions || [])
                  .flatMap(({ locations }) => locations)
                  .filter(Boolean),
                'id',
              )}
            />
          </Box>
          <Box>
            <Subheader>
              <Subheader.Title>Hiring team</Subheader.Title>
            </Subheader>
            <VStack gap="s-16">
              <LapeRadioSelectInput
                name="recruiter"
                label="Recruiter"
                selector={selectorKeys.employee}
              />
              <HideIfCommercial>
                <LapeRadioSelectInput
                  name="coordinator"
                  label="Coordinator"
                  required={false}
                  selector={selectorKeys.employee}
                  clearable
                />
              </HideIfCommercial>
            </VStack>
          </Box>
          <Description />
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          onClick={() => {
            navigateTo(getBackUrl(params, locationState), locationState)
          }}
          variant="secondary"
          elevated
        >
          Cancel
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          noPopup
          hideWhenNoChanges={false}
          onAfterSubmit={async resp => {
            onAfterSubmit()
            let hasError = false
            if (connectedRequisitions) {
              try {
                await connectRequisitions(
                  resp.id,
                  connectedRequisitions.map(
                    connectedRequisition => connectedRequisition.id,
                  ),
                )
              } catch {
                hasError = true
                handleRequisitionConnectionError()
              }
            }
            if (!hasError) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, {
                  ...params,
                  id: resp.id,
                }),
                locationState,
              )
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
      {!!values.specialisation && sidebar === 'connectRequisitions' && (
        <ConnectRequisitionsSidebar
          requisitions={connectedRequisitions}
          specialisation={values.specialisation}
          onClose={closeSidebar}
          onConnectRequisitions={handleConnectedRequisitions}
        />
      )}
      {sidebar === 'createNewSpecialisation' && (
        <CreateNewSpecialisationSidebar
          onClose={closeSidebar}
          onSubmit={specialisation => {
            values.specialisation = {
              id: specialisation.id,
              name: specialisation.name,
              role_id: specialisation.role.id,
              status: specialisation.status,
            }
          }}
        />
      )}
    </>
  )
}
