import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { get } from 'lodash'
import {
  Box,
  Button,
  Calendar,
  Cell,
  Flex,
  Footnote,
  Header,
  Icon,
  IconName,
  InputGroup,
  MoreBar,
  Popup,
  ProgressCircle,
  Skeleton,
  StatusPopup,
  Tag,
  Text,
  TextSkeleton,
  Token,
  Tooltip,
  useStatusPopup,
  useTooltip,
  VStack,
  Switch,
  Item,
} from '@revolut/ui-kit'
import { DateValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'

import RadioSelectInput, {
  createNewKey,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ColumnInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import {
  applyImportSession,
  bulkDeleteImportSessionRows,
  bulkEditImportSessionRows,
  createImportFromEntities,
  getImportSessionBulkItems,
  useGetImportSessionData,
  useGetImportSessionDataStats,
} from '@src/api/bulkDataImport'
import { IdAndName } from '@src/interfaces'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import FilterSelect from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { AccessGroupSelectorOption } from '@src/components/AccessGroup/AccessGroupSelectorOption'
import { formatDate, formatPercentage } from '@src/utils/format'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { OptionInterface } from '@src/interfaces/selectors'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { TableActionsProps } from './GenericEditableTable'
import { AxiosPromise } from 'axios'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { SelectTableWrapperOnChangeData } from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { ClearButton } from '@components/Inputs/partials/ClearButton/ClearButton'
import { LockedSyncIcon } from '@src/features/LockedSyncIcon/LockedSyncIcon'

const selectorQueryOptions = { cacheTime: Infinity, staleTime: Infinity }

export type GenericEditableTableCreateCallbackType<T extends string> = (
  type: T,
  onChangeAction: (entity: IdAndName) => void,
) => void

export type GenericEditableTableOnChange = (
  rowId: number,
  value: unknown,
  field: string,
) => void

export type GenericEditableTableOnBulkChange = (
  rowId: number,
  values: Record<string, unknown>,
) => void

export type EditableTableRenderMode = 'default' | 'preview' | 'bulkSession'

export type GenericEditableTableColumn<T> = (
  onChange: GenericEditableTableOnChange,
  mode?: EditableTableRenderMode,
) => ColumnInterface<ImportInterface<T>>

export type GenericEditableTableBulkColumn<T> = (
  onBulkChange: GenericEditableTableOnBulkChange,
  mode?: EditableTableRenderMode,
) => ColumnInterface<ImportInterface<T>>

type RadioSelectInputCellProps<T> = {
  onChange: GenericEditableTableOnChange
  selector: selectorKeys
  selectorField?: string
  field: string
  fieldName?: string
  data: ImportInterface<T>
  onCreateNewClick?: (onChangeAction: (entity: IdAndName) => void) => void
  customLabel?: React.ReactNode
  options?: OptionInterface[]
  clearable?: boolean
}

interface SelectCellProps {
  open: boolean
  setOpen: (open: boolean) => void
  children: React.ReactNode
  clearable?: boolean
  onClear?: VoidFunction
  synchronised?: boolean
}

export const SelectCell = forwardRef<HTMLDivElement, SelectCellProps>(
  ({ open, setOpen, children, clearable, onClear, synchronised }, ref) => {
    if (synchronised) {
      return (
        <Flex
          justifyContent="space-between"
          width="100%"
          height="100%"
          alignItems="center"
          color={Token.color.greyTone20}
        >
          {children}
          <LockedSyncIcon />
        </Flex>
      )
    }

    return (
      <Flex
        onClick={() => setOpen(!open)}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        use="button"
        type="button"
        color="inherit"
        ref={ref}
      >
        <Text textOverflow="ellipsis" overflow="hidden">
          {children}
        </Text>
        <Flex alignItems="center" gap="s-6">
          {clearable && onClear && (
            <ClearButton
              onClick={e => {
                e.stopPropagation()
                onClear()
              }}
            />
          )}
          <Icon
            color={Token.color.greyTone50}
            name={open ? 'ChevronUp' : 'ChevronDown'}
            size={16}
          />
        </Flex>
      </Flex>
    )
  },
)

type EmployeeEmailSelectorType = IdAndName & { email: string; avatar: string | null }

export const EmployeeSelectCell = <T,>({
  data,
  onChange,
  field,
  fieldName,
  onCreateNewClick,
}: Omit<RadioSelectInputCellProps<T>, 'selector' | 'useNameField'>) => {
  const { data: options, isLoading } = useGetSelectors<EmployeeEmailSelectorType>(
    selectorKeys.all_employees_avatar_email,
  )

  const value = options?.find(option => option.email === get(data.data, field))

  return (
    <CellWithError data={data} field={field}>
      <RadioSelectInput<EmployeeEmailSelectorType>
        onChange={option => {
          onChange(data.id, option?.email, field)
        }}
        selector={selectorKeys.all_employees_avatar_email}
        useQuery
        useQueryOptions={selectorQueryOptions}
        renderInput={(open, setOpen, ref) => (
          <SelectCell open={open} setOpen={setOpen} ref={ref}>
            {isLoading ? (
              <Skeleton />
            ) : value ? (
              <UserWithAvatar
                id={value.id}
                avatar={value.avatar}
                name={value.name}
                disableLink
              />
            ) : (
              `Select ${fieldName || field}`
            )}
          </SelectCell>
        )}
        showCreateNewButton={!!onCreateNewClick}
      />
    </CellWithError>
  )
}

export const RadioSelectInputCell = <T,>({
  data,
  onChange,
  selector,
  selectorField,
  field,
  fieldName,
  onCreateNewClick,
  customLabel,
  options,
  clearable,
}: RadioSelectInputCellProps<T>) => {
  const getOptionValue = (value: IdAndName<string | number> | null) =>
    selectorField ? get(value, selectorField) : value

  const isSynchronised = data.field_options?.synchronised?.includes(field)

  return (
    <CellWithError data={data} field={field}>
      <RadioSelectInput<IdAndName<number | string> & { status?: 'archived' }>
        onChange={option => {
          if (option?.id === createNewKey) {
            onCreateNewClick?.(newEntity => {
              onChange(data.id, getOptionValue(newEntity), field)
            })
          } else {
            onChange(data.id, getOptionValue(option), field)
          }
        }}
        filter={
          selector === selectorKeys.specialisations
            ? i => i.status !== 'archived'
            : undefined
        }
        selector={options ? () => Promise.resolve(options) : selector}
        useQuery={!options}
        useQueryOptions={selectorQueryOptions}
        fitInAnchor={false}
        renderInput={(open, setOpen, ref) => {
          const label =
            customLabel || get(data.data, `${field}.name`) || get(data.data, field)

          return (
            <SelectCell
              clearable={clearable && label}
              onClear={() => onChange(data.id, null, field)}
              open={open}
              setOpen={setOpen}
              ref={ref}
              synchronised={isSynchronised}
            >
              {label || `Select ${fieldName || field}`}
            </SelectCell>
          )
        }}
        showCreateNewButton={!!onCreateNewClick}
        refetchOnOpen
      />
    </CellWithError>
  )
}

interface TextCellProps<T> {
  data: ImportInterface<T>
  onChange: (rowId: number, value: string | number | null, field: string) => void
  onClick?: () => void
  field: string
  type?: TableCellInputType
  suffix?: string
  dataFieldTransformer?: (val: unknown) => string
  readonly?: boolean
  useNull?: boolean
}

export const TextCell = <T,>({
  data,
  onChange,
  onClick,
  field,
  type = TableCellInputType.text,
  suffix,
  dataFieldTransformer,
  readonly,
  useNull,
}: TextCellProps<T>) => {
  const dataField = dataFieldTransformer
    ? dataFieldTransformer(get(data.data, field))
    : get(data.data, field)

  const [value, setValue] = useState<string | number>(dataField || '')

  useEffect(() => {
    if (dataField !== undefined && dataField !== value) {
      setValue(dataField)
    }
  }, [dataField])

  const isSynchronised =
    data.field_options?.synchronised?.includes(field) ||
    data.data?.field_options?.synchronised?.includes(field)

  return (
    <CellWithError data={data} field={field}>
      <EditableCell
        type={type}
        value={value}
        onClick={onClick}
        onChange={val => setValue(val)}
        onBlur={val => {
          const newVal = typeof val === 'string' ? val.trim() : val
          setValue(newVal)

          let changeVal: string | number | null = newVal

          if (useNull && changeVal === '') {
            changeVal = null
          }
          onChange(data.id, changeVal, field)
        }}
        hidePencil
        defaultToZero
        suffix={suffix}
        readonly={readonly}
        synchronised={isSynchronised}
      />
    </CellWithError>
  )
}

export type DatePickerInputCellProps<T> = {
  data: ImportInterface<T>
  onChange: GenericEditableTableOnChange
  field: string
}

export const DatePickerInputCell = <T,>({
  data,
  onChange,
  field,
}: DatePickerInputCellProps<T>) => {
  const value = get(data.data, field)
  const calendarVariants = ['date' as const, 'month' as const, 'year' as const]

  const [open, setOpen] = useState(false)
  const [calendarValue, setCalendarValue] = useState(
    value == null ? new Date() : new Date(value),
  )
  const [calendarVariantIdx, setCalendarVariantIdx] = useState(0)

  return (
    <CellWithError data={data} field={field}>
      <Flex
        onClick={() => setOpen(!open)}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        use="button"
        type="button"
        color="inherit"
        style={{ cursor: 'pointer' }}
      >
        <Text style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
          {value ? formatDate(value) : '-'}
        </Text>
        <Icon color={Token.color.greyTone50} name="Calendar" size={16} />
      </Flex>

      <Popup
        open={open}
        onClose={() => {
          setOpen(false)
          onChange(data.id, calendarValue.toISOString(), field)
        }}
        variant="modal-view"
      >
        <Calendar
          variant={calendarVariants[calendarVariantIdx]}
          onCaptionClick={() => {
            setCalendarVariantIdx((calendarVariantIdx + 1) % 3)
          }}
          labelButtonClear="Clear"
          labelPrev="Previous"
          labelNext="Next"
          value={calendarValue}
          onChange={(newValue: DateValue) => {
            if (newValue === null) {
              setOpen(false)
              onChange(data.id, null, field)
              return
            }
            setCalendarValue(new Date(newValue))

            if (calendarVariants[calendarVariantIdx] === 'date') {
              setOpen(false)
              onChange(data.id, formatDate(newValue, 'yyyy-MM-dd'), field)
            } else {
              setCalendarVariantIdx(0)
            }
          }}
        />
      </Popup>
    </CellWithError>
  )
}

export type MultiSelectInputCellProps<T> = {
  label?: string
  value?: { id: number | string; name: string }[]
  onChange: GenericEditableTableOnChange
  selector: selectorKeys
  options?: OptionInterface[]
  field: string
  fieldName?: string
  data: ImportInterface<T>
}

export const MultiSelectInputCell = <T,>({
  data,
  onChange,
  selector,
  options,
  field,
  fieldName,
  label,
  value,
}: MultiSelectInputCellProps<T>) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const renderLabel = () => {
    if (label) {
      return label
    }

    const val: unknown = get(data.data, field)

    if (typeof val === 'string' && val) {
      return val
    }

    if (Array.isArray(val) && val.length) {
      return val
        .map((opt: unknown) =>
          typeof opt === 'object' && opt != null && 'name' in opt ? opt.name : null,
        )
        .filter(Boolean)
        .join(', ')
    }

    return `Select ${fieldName || field}`
  }

  return (
    <CellWithError data={data} field={field}>
      <SelectCell open={open} setOpen={setOpen} ref={anchorRef}>
        {renderLabel()}
      </SelectCell>
      <FilterSelect<{ id: string | number; name: string; description?: string }>
        value={value || get(data.data, field)}
        onChange={selectedOptions => onChange(data.id, selectedOptions, field)}
        selector={options ? () => Promise.resolve({ options }) : selector}
        open={open}
        onClose={() => setOpen(false)}
        anchorRef={anchorRef}
        useQuery
        useQueryOptions={selectorQueryOptions}
        renderOption={option => <AccessGroupSelectorOption {...option.value} />}
        width={500}
      />
    </CellWithError>
  )
}

interface CellWithErrorProps<T> {
  data: ImportInterface<T>
  field: string | string[]
  children: React.ReactNode
}

export const CellWithError = <T,>({ data, field, children }: CellWithErrorProps<T>) => {
  const tooltip = useTooltip()

  let errors: string | undefined
  let warnings: string | undefined
  let loading: boolean | undefined

  if (Array.isArray(field)) {
    const err = field.map(f => get(data.errors, f)).filter(Boolean)
    errors = err.length ? err.join('\n') : undefined
    const warn = field.map(f => get(data.warnings, f)).filter(Boolean)
    warnings = warn.length ? warn.join('\n') : undefined
    loading = field.some(f => get(data.loading, f))
  } else {
    errors = get(data.errors, field)?.join('\n')
    warnings = get(data.warnings, field)?.join('\n')
    loading = get(data.loading, field)
  }

  if (loading) {
    return <TextSkeleton />
  }

  return (
    <Flex alignItems="center" gap="s-8" {...tooltip.getAnchorProps()}>
      <Box
        flex="1"
        color={errors ? Token.color.danger : warnings ? Token.color.warning : undefined}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {children}
      </Box>
      <Tooltip {...tooltip.getTargetProps()} hidden={!errors && !warnings}>
        {errors || warnings}
      </Tooltip>
    </Flex>
  )
}

export type BulkEditOptionType = OptionInterface & {
  email: string
  iso_code?: string
}

interface BulkEditActionProps<T> extends TableActionsProps<T> {
  buttonIcon?: IconName
  field: string
  selector: selectorKeys
  label?: string
  selectorField?: keyof Pick<BulkEditOptionType, 'name' | 'email' | 'iso_code'>
  getCustomApiCall?: (
    sessionId: number,
    selectionContext: SelectTableWrapperOnChangeData<{ id: number }> | undefined,
    option: BulkEditOptionType | null,
  ) => () => AxiosPromise<void>
  onCreateNew?: (onChangeAction: (entity: IdAndName) => void) => void
}

export const BulkEditAction = <T,>({
  buttonIcon,
  field,
  selector,
  sessionData,
  selectionContext,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  label,
  selectorField = 'name',
  getCustomApiCall,
  onCreateNew,
}: BulkEditActionProps<T>) => {
  const visibleLabel = label || field

  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [option, setOption] = useState<BulkEditOptionType | null>(null)
  const statusPopup = useStatusPopup()

  const onAssign = (newOption?: BulkEditOptionType) => {
    if (!sessionData?.id) {
      return
    }

    if (newOption) {
      setOption(newOption)
    }

    const optionToSubmit = newOption || option

    const items = getSelectedItems()

    setBulkEditPending(true)

    const apiCall = getCustomApiCall
      ? getCustomApiCall(sessionData.id, selectionContext, optionToSubmit)
      : () =>
          bulkEditImportSessionRows(apiEndpoint, sessionData.id, items, {
            [field]: optionToSubmit?.[selectorField] || null,
          })

    apiCall()
      .then(() => {
        setPopupOpen(false)
        setBulkEditPending(false)
        refreshTableState()
        setOption(null)
      })
      .catch(error => {
        setBulkEditPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      <MoreBar.Action
        useIcon={buttonIcon}
        disabled={!selectionContext?.someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change {visibleLabel}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={bulkEditPending}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Assign {visibleLabel}</Header.Title>
        </Header>

        <RadioSelectInput
          label={`Select ${visibleLabel}`}
          value={option}
          selector={selector}
          filter={
            selector === selectorKeys.specialisations
              ? i => i.status !== 'archived'
              : undefined
          }
          onChange={val => {
            if (val?.id === createNewKey) {
              onCreateNew?.(entity => onAssign(entity as BulkEditOptionType))
            } else {
              setOption(val)
            }
          }}
          showCreateNewButton={!!onCreateNew}
          allowSetValueToCurrentOption
        />

        <Popup.Actions>
          <Footnote>This will replace the {visibleLabel} already assigned</Footnote>
          <Button
            onClick={() => onAssign()}
            elevated
            pending={bulkEditPending}
            disabled={!option}
          >
            Assign
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

interface BulkEditMultiSelectActionProps<T>
  extends Omit<BulkEditActionProps<T>, 'selectorField'> {
  buttonIcon: IconName
  field: string
  selector: selectorKeys
  label?: string
}

export const BulkEditMultiSelectAction = <T,>({
  buttonIcon,
  field,
  selector,
  sessionData,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  selectionContext,
  label,
}: BulkEditMultiSelectActionProps<T>) => {
  const visibleLabel = label || field

  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [options, setOptions] = useState<IdAndName[]>([])
  const statusPopup = useStatusPopup()

  const onAssign = () => {
    if (!sessionData?.id) {
      return
    }

    const items = getSelectedItems()

    setBulkEditPending(true)

    bulkEditImportSessionRows(apiEndpoint, sessionData.id, items, {
      [field]: options.map(item => item.name).join('; '),
    })
      .then(() => {
        setPopupOpen(false)
        setBulkEditPending(false)
        refreshTableState()
        setOptions([])
      })
      .catch(error => {
        setBulkEditPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      <MoreBar.Action
        useIcon={buttonIcon}
        disabled={!selectionContext?.someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change {visibleLabel}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={bulkEditPending}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Assign {visibleLabel}</Header.Title>
        </Header>

        <NewMultiSelect<IdAndName>
          label={`Select ${visibleLabel}`}
          selector={selector}
          onChange={selectedOptions => {
            setOptions(selectedOptions.map(option => option.value))
          }}
          value={options.map(optionValue => ({
            value: optionValue,
            label: optionValue.name,
          }))}
        />

        <Popup.Actions>
          <Footnote>This will replace the {visibleLabel} already assigned</Footnote>
          <Button
            onClick={onAssign}
            elevated
            pending={bulkEditPending}
            disabled={!options}
          >
            Assign
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

type SeniorityRangeEditPopupProps = {
  open: boolean
  onClose: () => void
  pending?: boolean
  initialSeniorityMin?: string
  initialSeniorityMax?: string
  seniorityMin?: OptionInterface | null
  seniorityMax?: OptionInterface | null
  setSeniorityMin: (val: OptionInterface | null) => void
  setSeniorityMax: (val: OptionInterface | null) => void
  onSubmit: () => void
}

export const SeniorityRangeEditPopup = ({
  open,
  onClose,
  pending,
  initialSeniorityMin,
  initialSeniorityMax,
  seniorityMin,
  seniorityMax,
  setSeniorityMin,
  setSeniorityMax,
  onSubmit,
}: SeniorityRangeEditPopupProps) => {
  const [allowMultipleSeniorities, setAllowMultipleSeniorities] = useState(
    Boolean(
      initialSeniorityMin &&
        initialSeniorityMax &&
        initialSeniorityMin !== initialSeniorityMax,
    ),
  )

  const { options } = useFetchOptions<IdAndName>(selectorKeys.seniority, true)

  if (options.length && initialSeniorityMin && !seniorityMin) {
    setSeniorityMin(
      options.find(seniority => seniority.value.name === initialSeniorityMin)?.value ||
        null,
    )
  }
  if (options.length && initialSeniorityMax && !seniorityMax) {
    setSeniorityMax(
      options.find(seniority => seniority.value.name === initialSeniorityMax)?.value ||
        null,
    )
  }

  return (
    <Popup
      open={open}
      onClose={onClose}
      variant="bottom-sheet"
      preventUserClose={pending}
    >
      <Header variant="bottom-sheet">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Assign seniority</Header.Title>
      </Header>

      <InputGroup>
        <Item use="label">
          <Item.Content>
            <Item.Title>Allow multiple seniorities</Item.Title>
          </Item.Content>
          <Item.Side>
            <Switch
              onChange={e => {
                const checked = e.currentTarget.checked
                setAllowMultipleSeniorities(checked)

                if (seniorityMax) {
                  setSeniorityMin(seniorityMax)
                }
              }}
              checked={allowMultipleSeniorities}
            />
          </Item.Side>
        </Item>

        <InputGroup variant="horizontal">
          {allowMultipleSeniorities ? (
            <>
              <RadioSelectInput
                label="Min Seniority"
                options={options}
                value={seniorityMin}
                onChange={setSeniorityMin}
              />
              <RadioSelectInput
                label="Max Seniority"
                options={options}
                value={seniorityMax}
                onChange={setSeniorityMax}
              />
            </>
          ) : (
            <RadioSelectInput<OptionInterface>
              label="Seniority"
              options={options}
              value={seniorityMax}
              onChange={value => {
                setSeniorityMin(value)
                setSeniorityMax(value)
              }}
            />
          )}
        </InputGroup>
      </InputGroup>

      <Popup.Actions>
        <Footnote>This will replace the seniority already assigned</Footnote>
        <Button
          onClick={onSubmit}
          elevated
          pending={pending}
          disabled={!seniorityMin || !seniorityMax}
        >
          Assign
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export const BulkEditSeniorityRangeAction = <T,>({
  sessionData,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  selectionContext,
}: Omit<
  BulkEditActionProps<T>,
  'buttonIcon' | 'selector' | 'selectorField' | 'label' | 'field'
>) => {
  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [seniorityMin, setSeniorityMin] = useState<OptionInterface | null>()
  const [seniorityMax, setSeniorityMax] = useState<OptionInterface | null>()
  const statusPopup = useStatusPopup()

  const onAssign = () => {
    if (!sessionData?.id) {
      return
    }

    const items = getSelectedItems()

    setBulkEditPending(true)

    bulkEditImportSessionRows(apiEndpoint, sessionData.id, items, {
      seniority_min: seniorityMin?.name || null,
      seniority_max: seniorityMax?.name || null,
    })
      .then(() => {
        setPopupOpen(false)
        setBulkEditPending(false)
        refreshTableState()
        setSeniorityMin(null)
        setSeniorityMax(null)
      })
      .catch(error => {
        setBulkEditPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      <MoreBar.Action
        useIcon="ArrowRightLeft"
        disabled={!selectionContext?.someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change seniority
      </MoreBar.Action>

      {popupOpen && (
        <SeniorityRangeEditPopup
          open
          onClose={() => setPopupOpen(false)}
          pending={bulkEditPending}
          seniorityMin={seniorityMin}
          setSeniorityMin={setSeniorityMin}
          seniorityMax={seniorityMax}
          setSeniorityMax={setSeniorityMax}
          onSubmit={onAssign}
        />
      )}
    </>
  )
}

interface BulkDeleteButtonProps<T> extends TableActionsProps<T> {}

interface BulkDeleteExistingEntitiesButtonProps<T>
  extends Pick<
    TableActionsProps<T>,
    'getSelectedItems' | 'refreshTableState' | 'selectionContext'
  > {
  apiHandler: (items: number[]) => AxiosPromise<void>
}

export const BulkDeleteExistingEntitiesButton = <T,>({
  getSelectedItems,
  refreshTableState,
  selectionContext,
  apiHandler,
}: BulkDeleteExistingEntitiesButtonProps<T>) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deleteInBulkPending, setDeleteInBulkPending] = useState(false)

  const statusPopup = useStatusPopup()

  const isDisabled =
    !selectionContext?.someSelected ||
    selectionContext?.selectedRowsData.some(
      data =>
        data.data?.field_options?.synchronised?.length &&
        data.data?.field_options?.synchronised?.length > 0,
    )

  return (
    <>
      <MoreBar.Action
        useIcon="Delete"
        disabled={isDisabled}
        onClick={() => setDeleteConfirmationOpen(true)}
        variant="negative"
      >
        Delete
      </MoreBar.Action>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onReject={() => setDeleteConfirmationOpen(false)}
        loading={deleteInBulkPending}
        onConfirm={async () => {
          if (!apiHandler) {
            return
          }

          const items = getSelectedItems()

          try {
            setDeleteInBulkPending(true)
            await apiHandler(items)
            refreshTableState()
            setDeleteConfirmationOpen(false)
          } catch (error) {
            statusPopup.show(
              <StatusPopup variant="error">
                <StatusPopup.Title>Failed to remove items</StatusPopup.Title>
                <StatusPopup.Description>
                  {getStringMessageFromError(error)}
                </StatusPopup.Description>
                <StatusPopup.Actions>
                  <Button onClick={() => statusPopup.hide()} elevated>
                    Close
                  </Button>
                </StatusPopup.Actions>
              </StatusPopup>,
            )
          } finally {
            setDeleteInBulkPending(false)
          }
        }}
        label="Are you sure you want to remove these items?"
        body=""
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}

export const BulkDeleteButton = <T,>({
  sessionData,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  selectionContext,
}: BulkDeleteButtonProps<T>) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deleteInBulkPending, setDeleteInBulkPending] = useState(false)

  const statusPopup = useStatusPopup()

  if (sessionData?.source === 'bamboohr') {
    return null
  }

  return (
    <>
      <MoreBar.Action
        useIcon="Delete"
        disabled={!selectionContext?.someSelected}
        onClick={() => setDeleteConfirmationOpen(true)}
        variant="negative"
      >
        Delete
      </MoreBar.Action>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onReject={() => setDeleteConfirmationOpen(false)}
        loading={deleteInBulkPending}
        onConfirm={async () => {
          if (!sessionData) {
            return
          }

          const items = getSelectedItems()

          try {
            setDeleteInBulkPending(true)
            await bulkDeleteImportSessionRows(apiEndpoint, sessionData.id, items)
            refreshTableState()
            setDeleteConfirmationOpen(false)
          } catch (error) {
            statusPopup.show(
              <StatusPopup variant="error">
                <StatusPopup.Title>Failed to remove items</StatusPopup.Title>
                <StatusPopup.Description>
                  {getStringMessageFromError(error)}
                </StatusPopup.Description>
                <StatusPopup.Actions>
                  <Button onClick={() => statusPopup.hide()} elevated>
                    Close
                  </Button>
                </StatusPopup.Actions>
              </StatusPopup>,
            )
          } finally {
            setDeleteInBulkPending(false)
          }
        }}
        label="Are you sure you want to remove these items?"
        body=""
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}

type ExistingEntitiesOptionType = IdAndName<string | number> & { email?: string }

interface BulkEditExistingEntitiesActionProps<T> extends TableActionsProps<T> {
  buttonIcon: IconName
  field: string
  selector: selectorKeys
  sessionRoute: string
  label?: string
  fieldsForBulkEdit?: string[]
  selectorField?: keyof Omit<ExistingEntitiesOptionType, 'id'>
  onCreateNew?: (onChangeAction: (entity: IdAndName) => void) => void
}

export const BulkEditExistingEntitiesAction = <T,>({
  buttonIcon,
  field,
  selector,
  sessionRoute,
  getSelectedItems,
  apiEndpoint,
  selectionContext,
  label,
  refreshTableState,
  fieldsForBulkEdit,
  selectorField = 'name',
  onCreateNew,
}: BulkEditExistingEntitiesActionProps<T>) => {
  const statusPopup = useStatusPopup()

  const visibleLabel = label || field

  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [option, setOption] = useState<ExistingEntitiesOptionType | null>(null)
  const [sessionId, setSessionId] = useState<number>()
  const [actionState, setActionState] = useState<
    'check-ready-for-review' | 'check-stats' | 'apply-changes'
  >()
  const [progress, setProgress] = useState(0)

  const showErrorsOccurredPopup = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>We found some errors</StatusPopup.Title>
        <StatusPopup.Description>
          Please review and correct, or proceed to skip
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            onClick={() => {
              statusPopup.hide()
              navigateTo(pathToUrl(sessionRoute, { id: sessionId, type: field }))
            }}
          >
            Fix errors
          </Button>
          <Button variant="secondary" onClick={statusPopup.hide}>
            Try again
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const resetState = () => {
    setSessionId(undefined)
    setProgress(0)
    setActionState(undefined)
  }

  const { data: stats } = useGetImportSessionDataStats(
    apiEndpoint,
    sessionId,
    actionState === 'check-stats' || actionState === 'apply-changes',
  )

  const { data: importSession } = useGetImportSessionData(
    apiEndpoint,
    sessionId,
    actionState === 'check-ready-for-review',
  )

  const statsProgress = useMemo(
    () => (stats ? (stats.total - stats.state_pending) / stats.total : undefined),
    [stats],
  )

  if (statsProgress && progress !== statsProgress) {
    setProgress(statsProgress)
  }

  if (stats && stats.state_failure > 0) {
    resetState()
    showErrorsOccurredPopup()
  }

  if (actionState === 'apply-changes' && progress === 1) {
    resetState()
    refreshTableState()
    setOption(null)
    setPopupOpen(false)

    statusPopup.show(
      <StatusPopup variant="success">
        <StatusPopup.Title>Successfully assigned</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  if (
    actionState === 'check-ready-for-review' &&
    importSession?.state.id === 'ready_for_review'
  ) {
    setActionState('check-stats')
  }

  if (stats && sessionId && actionState === 'check-stats') {
    setActionState('apply-changes')

    if (stats.errors > 0) {
      resetState()
      showErrorsOccurredPopup()
    } else {
      applyImportSession(apiEndpoint, sessionId).catch(error => {
        resetState()

        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to assign</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
    }
  }

  const onAssign = async (newOption?: BulkEditOptionType) => {
    const items = getSelectedItems()

    setBulkEditPending(true)

    if (newOption) {
      setOption(newOption)
    }

    const optionToSubmit = newOption || option

    try {
      const createImportSessionResponse = await createImportFromEntities(
        apiEndpoint,
        items,
        fieldsForBulkEdit,
      )
      const id = createImportSessionResponse.data.bulk_upload_id
      const sessionIdsResponse = await getImportSessionBulkItems(apiEndpoint, id)

      const bulkEditResponse = await bulkEditImportSessionRows(
        apiEndpoint,
        id,
        sessionIdsResponse.data.ids,
        {
          [field]: optionToSubmit?.[selectorField] || null,
        },
      )

      if (bulkEditResponse?.data.edited === 0) {
        throw new Error('Nothing was selected')
      }

      setProgress(0)

      setSessionId(id)
      setActionState('check-ready-for-review')
      setBulkEditPending(false)
    } catch (error) {
      setBulkEditPending(false)
      resetState()

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to assign</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }

  return (
    <>
      <MoreBar.Action
        useIcon={buttonIcon}
        disabled={!selectionContext?.someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change {visibleLabel}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={bulkEditPending || !!actionState}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Assign {visibleLabel}</Header.Title>
        </Header>

        {actionState ? (
          <Cell>
            <VStack width="100%" align="center" space="s-8">
              <ProgressCircle strokeWidth={3} size={94} value={progress}>
                <ProgressCircle.Text>{formatPercentage(progress)}</ProgressCircle.Text>
              </ProgressCircle>

              <Text variant="primary">Assigning new {visibleLabel}</Text>
            </VStack>
          </Cell>
        ) : (
          <>
            <RadioSelectInput<IdAndName<string | number> & { status?: 'archived' }>
              label={`Select ${visibleLabel}`}
              value={option}
              onChange={val => {
                if (val?.id === createNewKey) {
                  onCreateNew?.(newEntity => onAssign(newEntity as BulkEditOptionType))
                } else {
                  setOption(val)
                }
              }}
              selector={selector}
              filter={
                selector === selectorKeys.specialisations
                  ? i => i.status !== 'archived'
                  : undefined
              }
              showCreateNewButton={!!onCreateNew}
              allowSetValueToCurrentOption
            >
              {selector === selectorKeys.groups
                ? opt => <AccessGroupSelectorOption {...opt.value} />
                : undefined}
            </RadioSelectInput>

            <Popup.Actions>
              <Footnote>This will replace the {visibleLabel} already assigned</Footnote>
              <Button
                onClick={() => onAssign()}
                elevated
                pending={bulkEditPending}
                disabled={!option}
              >
                Assign
              </Button>
            </Popup.Actions>
          </>
        )}
      </Popup>
    </>
  )
}

interface SeniorityValueProps {
  minSeniority?: string
  maxSeniority?: string
  error?: boolean
}

export const SeniorityValue = ({
  minSeniority,
  maxSeniority,
  error,
}: SeniorityValueProps) => {
  if (!minSeniority && !maxSeniority) {
    return <>Select seniority</>
  }

  if (!minSeniority && maxSeniority) {
    return (
      <Tag
        variant="faded"
        color={error ? Token.color.red : undefined}
        bg={error ? Token.color.inputError : undefined}
      >
        {maxSeniority}
      </Tag>
    )
  }

  return (
    <Tag
      variant="faded"
      color={error ? Token.color.red : undefined}
      bg={error ? Token.color.inputError : undefined}
    >
      {minSeniority === maxSeniority ? minSeniority : `${minSeniority} - ${maxSeniority}`}
    </Tag>
  )
}
