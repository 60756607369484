import {
  EligibilityCheckItem,
  EligibilityCheckOptionsInterface,
  ProgressionTrack,
} from '@src/interfaces/promotions'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useEffect, useState } from 'react'
import { checkPromotionEligibility } from '@src/api/promotions'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import {
  defaultMissedEligibilityCheckItem,
  defaultPassedEligibilityCheckItem,
} from '@src/features/GrowthPlans/GrowthPlan/components/PromotionEligibility/constants'

interface Props {
  data: EligibilityCheckItem[]
  isLoading: boolean
}

export const usePromotionEligibilityCriteria = (
  employee: EmployeeInterface,
  growthPlan: GrowthPlanInterface,
): Props => {
  const [isLoading, setIsLoading] = useState(true)
  const [criteria, setCriteria] = useState<EligibilityCheckItem[]>([])

  useEffect(() => {
    const options: EligibilityCheckOptionsInterface = {
      seniority: growthPlan.target_seniority,
      specialisation: growthPlan.target_specialisation,
      progression_track: ProgressionTrack.Normal,
    }

    checkPromotionEligibility(employee.id, options)
      .then(
        ({
          data: {
            eligibility_criteria_results: eligibilityCriteria,
            meets_eligibility_criteria: isEligible,
          },
        }) => {
          if (eligibilityCriteria.length === 0) {
            setCriteria(
              isEligible
                ? [defaultPassedEligibilityCheckItem]
                : [defaultMissedEligibilityCheckItem],
            )
          } else {
            setCriteria(
              [...eligibilityCriteria].sort(
                (lhs, rhs) => Number(rhs.passed) - Number(lhs.passed),
              ),
            )
          }
        },
      )
      .catch(() => {
        setCriteria([])
      })
      .finally(() => setIsLoading(false))
  }, [employee.id, growthPlan.target_specialisation.id, growthPlan.target_seniority.id])

  return {
    isLoading,
    data: criteria,
  }
}
