import React from 'react'
import upperFirst from 'lodash/upperFirst'
import isEmpty from 'lodash/isEmpty'
import {
  ApprovalFlowResponse,
  ApprovalFlowStep,
  ApprovalStatuses,
} from '@src/interfaces/approvalFlow'
import {
  Box,
  chain,
  ExpandableCell,
  Flex,
  Skeleton,
  AvatarSkeleton,
  Text,
  TextProps,
  TextSkeleton,
  Token,
} from '@revolut/ui-kit'
import { Check, Cross } from '@revolut/icons'
import { ColoredText } from '@components/CommonSC/General'
import Icon from '@components/Icon/Icon'
import Approver from '@src/features/ApprovalFlow/Approver'
import { removeUnderscore } from '@src/utils/string'
import { formatDate } from '@src/utils/format'

export interface ApprovalFlowProps {
  steps: ApprovalFlowResponse | null
  isLoading: boolean
  openByDefault?: boolean
  onViewRejectionReasonClick?: (comment?: string | null) => void
}

export interface StepInfo {
  status: ApprovalStatuses
  index: number
}

export const formatStepName = (name: string) => {
  let stepName = name

  if (stepName.startsWith('hr_')) {
    stepName = stepName.replace('hr_', 'HR_')
  }
  return removeUnderscore(stepName)
}

export const getCurrentStepInfo = (steps: ApprovalFlowStep[]): StepInfo => {
  for (let i = 0; i < steps.length; i++) {
    const stepStatus = steps[i].approval_status
    if (
      [
        ApprovalStatuses.Pending,
        ApprovalStatuses.RequiresChanges,
        ApprovalStatuses.Rejected,
      ].includes(stepStatus)
    ) {
      return { status: stepStatus, index: i }
    }
  }
  const lastStepIndex = steps.length - 1
  return { status: steps[lastStepIndex].approval_status, index: lastStepIndex }
}

const renderStatus = (
  steps: ApprovalFlowStep[],
  currentStep: StepInfo,
): React.ReactNode => {
  const numSteps = steps.length
  const stepName = formatStepName(steps[currentStep.index].approval_step)

  const commonTextProps: Partial<TextProps> = { fontWeight: 500 }

  if (
    currentStep.status === ApprovalStatuses.Approved ||
    (currentStep.status === ApprovalStatuses.Skipped &&
      currentStep.index === numSteps - 1)
  ) {
    return (
      <Text {...commonTextProps} color="green">
        {chain(`${numSteps}/${numSteps}`, upperFirst(ApprovalStatuses.Approved))}
      </Text>
    )
  }
  if (
    currentStep.status === ApprovalStatuses.Pending ||
    currentStep.status === ApprovalStatuses.RequiresChanges
  ) {
    return (
      <Text {...commonTextProps} color="warning">
        {chain(
          `${currentStep.index + 1}/${numSteps}`,
          `${upperFirst(ApprovalStatuses.Pending)} with ${stepName}`,
        )}
      </Text>
    )
  }
  if (currentStep.status === ApprovalStatuses.Rejected) {
    return (
      <Text {...commonTextProps} color="error">
        {chain(
          `${currentStep.index + 1}/${numSteps}`,
          `${ApprovalStatuses.Rejected} by ${stepName}`,
        )}
      </Text>
    )
  }
  return null
}

const renderStepStatusIcon = (stepStatus: ApprovalStatuses, isCurrentStep: boolean) => {
  const iconSize = 16

  switch (stepStatus) {
    case ApprovalStatuses.Approved:
    case ApprovalStatuses.Skipped:
      return <Check size={iconSize} color="green" data-testid="approved-icon" />
    case ApprovalStatuses.Rejected:
      return <Cross size={iconSize} color="red" />
    case ApprovalStatuses.Pending:
    case ApprovalStatuses.RequiresChanges:
      return (
        <ColoredText color={isCurrentStep ? Token.color.warning : Token.color.greyTone20}>
          <Icon type="Time" size="tiny" />
        </ColoredText>
      )
    default:
      return null
  }
}

const getStepStatusText = (step: ApprovalFlowStep) => {
  switch (step.approval_status) {
    case ApprovalStatuses.Skipped:
      return chain(
        upperFirst(formatStepName(step.approval_step)),
        upperFirst(ApprovalStatuses.Approved),
      )
    case ApprovalStatuses.RequiresChanges:
      return chain(
        upperFirst(formatStepName(step.approval_step)),
        upperFirst(ApprovalStatuses.Pending),
      )
    default:
      return chain(
        upperFirst(formatStepName(step.approval_step)),
        upperFirst(step.approval_status),
      )
  }
}

const renderApprovers = (
  step: ApprovalFlowStep,
  onViewRejectionReasonClick?: (comment?: string | null) => void,
) => {
  const action =
    step.approval_status === ApprovalStatuses.Skipped
      ? ApprovalStatuses.Approved
      : step.approval_status.replace('_', ' ')

  const caption = step.approval_date_time
    ? `${action} on ${formatDate(step.approval_date_time)}`
    : undefined

  const onViewReason = step.rejection_comment?.length
    ? onViewRejectionReasonClick
    : undefined

  if (!step.approver || step.approver?.id === step.expected_approver?.id) {
    return (
      <Approver
        {...step.expected_approver}
        caption={caption}
        isSkipped={false}
        rejectionComment={step.rejection_comment}
        onViewRejectionReasonClick={onViewReason}
      />
    )
  }

  if (step.approval_status === ApprovalStatuses.RequiresChanges) {
    return (
      <>
        {step.expected_approver?.id !== step.approver.id && (
          <Approver {...step.expected_approver} isSkipped={false} />
        )}
        <Approver
          {...step.approver}
          caption={caption}
          isSkipped={false}
          rejectionComment={step.rejection_comment}
          onViewRejectionReasonClick={onViewReason}
          mt="0.5rem"
        />
      </>
    )
  }

  return (
    <>
      <Approver {...step.expected_approver} isSkipped />
      <Approver
        {...step.approver}
        caption={caption}
        isSkipped={false}
        rejectionComment={step.rejection_comment}
        onViewRejectionReasonClick={onViewReason}
        mt="0.5rem"
      />
    </>
  )
}

export interface ApprovalFlowContentProps {
  currentStep: StepInfo
  steps: ApprovalFlowResponse
  onViewRejectionReasonClick?: (comment?: string | null) => void
}

export const ApprovalFlowContent = ({
  currentStep,
  steps,
  onViewRejectionReasonClick,
}: ApprovalFlowContentProps) => {
  return (
    <Box>
      {steps.map((step, index) => {
        return (
          <Flex pt="1rem" alignItems="flex-start" key={index}>
            <Box pt="s-2" pr="0.5rem">
              {renderStepStatusIcon(step.approval_status, currentStep.index === index)}
            </Box>
            <Box>
              <Text>{getStepStatusText(step)}</Text>
              <Box pt="0.5rem">{renderApprovers(step, onViewRejectionReasonClick)}</Box>
            </Box>
          </Flex>
        )
      })}
    </Box>
  )
}

const ApprovalFlow = ({
  steps,
  isLoading,
  openByDefault = false,
  onViewRejectionReasonClick,
}: ApprovalFlowProps) => {
  if (isLoading || !steps) {
    const numSteps = steps?.length || 2
    const stepsMock: number[] = []
    for (let i = 0; i < numSteps; i++) {
      stepsMock.push(i)
    }

    return (
      <ExpandableCell prefersExpanded={openByDefault}>
        <ExpandableCell.Title>Approval status</ExpandableCell.Title>
        <ExpandableCell.Content>
          <TextSkeleton size={8} />
        </ExpandableCell.Content>
        <ExpandableCell.Note>
          <Box>
            {stepsMock.map(key => (
              <Box pt="1rem" key={key}>
                <Skeleton width="50%" />
                <Flex alignItems="center" ml="5%" mt="s-16" width="35%">
                  <AvatarSkeleton size={20} />
                  <Flex ml="s-8" flex="1 0 auto">
                    <Skeleton width="100%" />
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Box>
        </ExpandableCell.Note>
      </ExpandableCell>
    )
  }

  // Sometimes BE returns {} instead of [] when the data is missing in the test envs ¯\_(ツ)_/¯
  if (isEmpty(steps)) {
    return null
  }

  const currentStep = getCurrentStepInfo(steps)

  return (
    <ExpandableCell prefersExpanded={openByDefault}>
      <ExpandableCell.Title>Approval status</ExpandableCell.Title>
      <ExpandableCell.Content>{renderStatus(steps, currentStep)}</ExpandableCell.Content>
      <ExpandableCell.Note>
        <ApprovalFlowContent
          currentStep={currentStep}
          steps={steps}
          onViewRejectionReasonClick={onViewRejectionReasonClick}
        />
      </ExpandableCell.Note>
    </ExpandableCell>
  )
}

export default React.memo(ApprovalFlow)
