import { FinalGrade } from '@src/interfaces/performance'
import { Color, IconName, Token } from '@revolut/ui-kit'

export const gradeSettings: Record<
  FinalGrade,
  { color: Color; icon: IconName | undefined }
> = {
  [FinalGrade.Empty]: {
    color: Token.color.foreground,
    icon: undefined,
  },
  [FinalGrade.None]: {
    color: Token.color.foreground,
    icon: undefined,
  },
  [FinalGrade.Poor]: {
    color: Token.color.warning,
    icon: '16/ExclamationMarkSign',
  },
  [FinalGrade.AverageMinus]: {
    color: Token.color.foreground,
    icon: 'TurboTransfer',
  },
  [FinalGrade.AveragePlus]: {
    color: Token.color.success,
    icon: 'SocialLike',
  },
  [FinalGrade.Strong]: {
    color: Token.color.success,
    icon: 'Trophy',
  },
  [FinalGrade.Exceptional]: {
    color: Token.color.purple,
    icon: 'Cleaning',
  },
} as const

export const getOverallProgressGrade = (num?: number) => {
  if (num === undefined) {
    return FinalGrade.None
  }

  if (num < 50) {
    return FinalGrade.Poor
  }
  if (num < 70) {
    return FinalGrade.AverageMinus
  }
  if (num < 80) {
    return FinalGrade.AveragePlus
  }
  if (num < 100) {
    return FinalGrade.Strong
  }
  if (num >= 100) {
    return FinalGrade.Exceptional
  }
  return FinalGrade.None
}
