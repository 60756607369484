import React from 'react'
import {
  Cycle,
  CycleStats,
  CycleSummaryData,
  CycleTablesData,
  PerformanceSummaryData,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { Avatar, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { Stats } from '@src/interfaces/data'
import { QuarterGoalsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterGoalsTable'
import { useTable } from '@components/Table/hooks'
import QuarterTableWrapper from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterTableWrapper'
import { QuarterRoadmapsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterRoadmapTable'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import { QuarterKarmaTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterKarmaTable'
import { CollapsableTableWrapper } from '@src/pages/Forms/DepartmentForm/Performance/Summary/CollapsableTableWrapper'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'

interface YearSummaryProps extends CycleSummaryData {
  summaryStats: PerformanceSummaryData | undefined
}
const YearSummary = ({
  goalTableRequests,
  goalsSettings,
  roadmapsTableRequests,
  roadmapsSettings,
  karmaTableRequests,
  karmaSettings,
  selectedCycle,
  cycleStats,
  summaryStats,
}: YearSummaryProps) => {
  const karmaCycleIds = selectedCycle.karma_cycle_ids.join(',')
  const karmaTable = useTable<KarmaInterface, KarmaStatsInterface>(
    karmaTableRequests,
    [
      ...karmaSettings.initialFilters,
      // yes, we actually need to send karma_cycle_ids as 'review_cycle__id'
      {
        columnName: 'review_cycle__id',
        filters: [
          {
            name: selectedCycle?.name,
            id: karmaCycleIds,
          },
        ],

        nonResettable: true,
      },
    ],
    karmaSettings.initialSort,
    { omitKeys: ['cycle__id'] },
  )

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="s-16">
          <Avatar useIcon="BarChart" />
          <Text variant="emphasis1">Department Performance Summary</Text>
        </Flex>
      </Flex>
      <CollapsableTableWrapper
        key="goals"
        readOnly
        headerTitle="Goals"
        headerStat={summaryStats?.goal_progress.percent}
        headerStatColor={
          summaryStats
            ? getPercentColor(summaryStats?.goal_progress.percent * 100)
            : Token.color.foreground
        }
        icon="Target"
      >
        <VStack
          width="100%"
          mx="s-2"
          bg={Token.color.groupedBackground}
          borderRadius="0 0 14px 14px"
          marginBottom="s-2"
          overflow="hidden"
        >
          {selectedCycle.review_cycle_ids.map((cycleId, index) => (
            <CollectionItem
              key={`${cycleId}goal`}
              cycleId={cycleId}
              index={index}
              type="goal"
              goalsSettings={goalsSettings}
              roadmapsSettings={roadmapsSettings}
              selectedCycle={selectedCycle}
              goalTableRequests={goalTableRequests}
              roadmapsTableRequests={roadmapsTableRequests}
              karmaTableRequests={karmaTableRequests}
              karmaSettings={karmaSettings}
              cycleStats={cycleStats?.find(
                cycleStat => cycleStat.review_cycle_id === cycleId,
              )}
            />
          ))}
        </VStack>
      </CollapsableTableWrapper>

      <CollapsableTableWrapper
        headerTitle="Roadmap"
        readOnly
        icon="Map"
        headerStat={summaryStats?.roadmap_progress.percent}
        headerStatColor={
          summaryStats
            ? getPercentColor(summaryStats?.roadmap_progress.percent * 100)
            : Token.color.foreground
        }
      >
        <VStack
          width="100%"
          mx="s-2"
          bg={Token.color.groupedBackground}
          borderRadius="0 0 14px 14px"
          marginBottom="s-2"
          overflow="hidden"
        >
          {selectedCycle.review_cycle_offsets.map((cycleId, index) => (
            <CollectionItem
              key={`${cycleId}roadmap`}
              cycleId={cycleId}
              index={index}
              type="roadmap"
              goalsSettings={goalsSettings}
              roadmapsSettings={roadmapsSettings}
              karmaSettings={karmaSettings}
              selectedCycle={selectedCycle}
              goalTableRequests={goalTableRequests}
              roadmapsTableRequests={roadmapsTableRequests}
              karmaTableRequests={karmaTableRequests}
              cycleStats={cycleStats?.find(
                cycleStat =>
                  cycleStat.review_cycle_id === selectedCycle.review_cycle_ids[index],
              )}
            />
          ))}
        </VStack>
      </CollapsableTableWrapper>

      <CollapsableTableWrapper
        headerTitle="Karma"
        readOnly
        icon="ArrowsSort"
        headerStat={summaryStats?.karma_percentage.percent}
        headerStatColor={
          summaryStats
            ? summaryStats?.karma_percentage.percent >= 0.5
              ? Token.color.success
              : Token.color.danger
            : Token.color.foreground
        }
      >
        <QuarterKarmaTable table={karmaTable} />
      </CollapsableTableWrapper>
    </>
  )
}

interface CollectionItemProps extends CycleTablesData {
  cycleId: number
  index: number
  type: 'goal' | 'roadmap'
  selectedCycle: Cycle
  cycleStats: CycleStats | undefined
}
const CollectionItem = ({
  cycleId,
  index,
  type,
  goalsSettings,
  roadmapsSettings,
  selectedCycle,
  roadmapsTableRequests,
  goalTableRequests,
  cycleStats,
}: CollectionItemProps) => {
  const goalsTable = useTable(
    goalTableRequests,
    [
      ...goalsSettings.initialFilters,
      {
        columnName: 'cycle__id',
        filters: [{ id: cycleId, name: selectedCycle?.name }],
        nonResettable: true,
        disableQueryParam: true,
      },
    ],
    goalsSettings.initialSort,
    {
      parentIdFilterKey: 'parent_id',
      childrenFetchOmitFilterKeys: ['object_id'],
      disable: type !== 'goal',
    },
  )

  const roadmapsTable = useTable<RoadmapInterface, Stats>(
    roadmapsTableRequests,
    [
      ...roadmapsSettings.initialFilters,
      {
        columnName: 'review_cycle__offset',
        filters: [
          { id: selectedCycle.review_cycle_offsets[index], name: selectedCycle?.name },
        ],
        nonResettable: true,
        disableQueryParam: true,
      },
    ],
    roadmapsSettings.initialSort,
    { disable: type !== 'roadmap', omitKeys: ['cycle__id'] },
  )

  const getQuarterStats = () => {
    switch (type) {
      case 'goal': {
        return cycleStats?.goal_progress
      }
      case 'roadmap': {
        return cycleStats?.roadmap_progress
      }
      default:
        return null
    }
  }
  return (
    <QuarterTableWrapper
      isInitiallyCollapsed={index === 0}
      cycleStat={getQuarterStats()}
      cycleName={cycleStats?.name}
    >
      {type === 'goal' && <QuarterGoalsTable table={goalsTable} />}
      {type === 'roadmap' && <QuarterRoadmapsTable table={roadmapsTable} />}
    </QuarterTableWrapper>
  )
}
export default YearSummary
