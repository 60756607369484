import React, { useState } from 'react'
import { Avatar, Group, Item, Widget } from '@revolut/ui-kit'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import {
  GrowthPlanActionInterface,
  GrowthPlanInterface,
} from '@src/interfaces/growthPlans'
import { ActionItem } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/ActionItem'
import { useMarkAction } from '@src/features/GrowthPlans/hooks/useCompleteAction'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { ERRORS } from '@src/constants/notifications'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { MarkActionItemPopup } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/MarkActionItemPopup'
import {
  getMarkActionErrorMessage,
  getMarkActionSuccessMessage,
  getPopupConfirmLabel,
  getPopupDescription,
  getPopupTitle,
} from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/constants'
import { useCanMarkGrowthPlanActions } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'

interface Props {
  growthPlan: GrowthPlanInterface
  onMarkAction?: () => Promise<void>
}

export const ActionItemsWidget = ({ growthPlan, onMarkAction }: Props) => {
  const { mark, isLoading } = useMarkAction(growthPlan)
  const canMarkActions = useCanMarkGrowthPlanActions(growthPlan)

  const [selectedAction, setSelectedAction] = useState<GrowthPlanActionInterface>()
  const [comment, setComment] = useState<string>()
  const showStatusPopup = useShowStatusPopup()

  const makeItemClickHandler = (action: GrowthPlanActionInterface) => () => {
    if (canMarkActions) {
      setSelectedAction(action)
    }
  }

  const handleChangeComment = (value: string) => {
    setComment(value)
  }

  const handleCancel = () => {
    setSelectedAction(undefined)
    setComment(undefined)
  }

  const handleMarkAction = async () => {
    if (!selectedAction || !comment || !canMarkActions) {
      return
    }

    try {
      await mark(selectedAction, comment)

      if (onMarkAction) {
        await onMarkAction()
      }

      showStatusPopup({
        status: 'success',
        title: getMarkActionSuccessMessage(selectedAction),
      })
    } catch (err) {
      showStatusPopup({
        status: 'error',
        title: getMarkActionErrorMessage(selectedAction),
        description: getStringMessageFromError(err, ERRORS.UNKNOWN_REFRESH),
      })
    } finally {
      setSelectedAction(undefined)
      setComment(undefined)
    }
  }

  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Questionnaire" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Growth plan actions</Item.Title>
          <Item.Description>
            To-do items for employee to successfully complete growth plan
          </Item.Description>
        </Item.Content>
      </Item>
      <Group px="s-24" pb="s-16" style={transparentThemeBackgroundOverrides}>
        {growthPlan.actions.map(action => (
          <ActionItem
            key={action.id}
            action={action}
            disabled={!canMarkActions}
            onClick={makeItemClickHandler(action)}
          />
        ))}
      </Group>
      {selectedAction && (
        <MarkActionItemPopup
          isOpen
          title={getPopupTitle(selectedAction)}
          description={getPopupDescription(selectedAction)}
          confirmLabel={getPopupConfirmLabel(selectedAction)}
          disabledTooltipText="Cannot perform an action without leaving a comment"
          isDisabled={!comment}
          isPending={isLoading}
          comment={comment}
          handleConfirm={handleMarkAction}
          handleCancel={handleCancel}
          handleChangeComment={handleChangeComment}
        />
      )}
    </Widget>
  )
}
