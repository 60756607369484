import React, { useState } from 'react'
import { Button, Header, Popup, StatusPopup, Text, useStatusPopup } from '@revolut/ui-kit'
import StagePicker from '@src/pages/Forms/Candidate/InterviewProgress/components/StagePicker'
import { changeStage } from '@src/pages/Forms/Candidate/utils'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { skipAutomaticScheduling } from '@src/api/recruitment/interviews'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGetStagesWithoutDuplicates } from '@src/pages/Forms/Candidate/InterviewProgress/useGetStagesWithoutDuplicates'

type Props = {
  open: boolean
  onClose: () => void
  data: InterviewStageWithoutRoundInterface[]
  currentStageId?: number
  roundId: number
  onChange: (round: InterviewRoundInterface) => void
  noSuccessPopup?: boolean
}

export const ChangeStagePopup = ({
  open,
  onClose,
  data,
  currentStageId,
  roundId,
  onChange,
  noSuccessPopup,
}: Props) => {
  const statusPopup = useStatusPopup()
  const [stageId, setStageId] = useState<number>()
  const [pending, setPending] = useState(false)
  const featureFlags = useSelector(selectFeatureFlags)
  const automaticInterviewScheduling = featureFlags?.includes(
    FeatureFlags.AutomaticInterviewScheduling,
  )
  const stages = useGetStagesWithoutDuplicates(data)

  const handleChangeStage = async (
    newStageId: number,
    cancelAutomaticScheduling?: boolean,
  ) => {
    setPending(true)
    try {
      if (cancelAutomaticScheduling) {
        await skipAutomaticScheduling(roundId, newStageId)
      }
      const round = await changeStage(roundId, newStageId)

      if (!noSuccessPopup) {
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>
              Changed stage to {round.latest_interview_stage?.title}
            </StatusPopup.Title>
          </StatusPopup>,
        )
      }

      onChange(round)
      onClose()
    } finally {
      setPending(false)
    }
  }

  const handleChangeStageToAutomatedScheduledStage = (
    stage: InterviewStageWithoutRoundInterface,
  ) => {
    statusPopup.show(
      <StatusPopup variant="warning" preventUserClose>
        <StatusPopup.Title>Continue with automation?</StatusPopup.Title>
        <StatusPopup.Description>
          <Text fontWeight="bold">{stage.title}</Text> has a scheduling automation. An
          email with available interview slots will be sent to this candidate. Do you want
          to continue with automation?
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            variant="secondary"
            onClick={() => {
              statusPopup.hide()
              handleChangeStage(stage.id, true)
            }}
          >
            Cancel automation
          </Button>
          <Button
            elevated
            onClick={() => {
              statusPopup.hide()
              handleChangeStage(stage.id)
            }}
          >
            Continue
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const onChangeState = async () => {
    if (!stageId) {
      return
    }
    const newStage = stages.find(({ id }) => id === stageId)
    if (
      automaticInterviewScheduling &&
      newStage?.automation_status &&
      !newStage?.automation_disabled
    ) {
      handleChangeStageToAutomatedScheduledStage(newStage)
    } else {
      handleChangeStage(stageId)
    }
  }

  return (
    <Popup open={open} onClose={onClose} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>Change stage</Header.Title>
      </Header>
      <StagePicker
        data={stages}
        onClick={setStageId}
        selectedId={currentStageId}
        currentId={currentStageId}
      />
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button
          elevated
          disabled={!stageId || pending || currentStageId === stageId}
          onClick={onChangeState}
          pending={pending}
        >
          Submit
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
