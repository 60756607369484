import React from 'react'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'
import { Button, DropdownItemProps, Text } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { skipAutomaticScheduling } from '@src/api/recruitment/interviews'
import { changeStage } from '@src/pages/Forms/Candidate/utils'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface Props {
  onLoading: (loading: boolean) => void
  onClose: VoidFunction
  onRefresh?: VoidFunction
  roundId: number
  stage: InterviewStageWithoutRoundInterface
  itemProps: DropdownItemProps
  menuType: MenuActionType
}

export const MoveStageAction = ({
  menuType,
  roundId,
  stage,
  onLoading,
  onClose,
  onRefresh,
  itemProps,
}: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const automaticInterviewScheduling = featureFlags?.includes(
    FeatureFlags.AutomaticInterviewScheduling,
  )
  const showStatusPopup = useShowStatusPopup()

  const changeStageHandler = async (cancelAutomaticScheduling?: boolean) => {
    onLoading(true)
    onClose()

    try {
      if (cancelAutomaticScheduling) {
        await skipAutomaticScheduling(roundId, stage.id)
      }
      const resp = await changeStage(roundId, stage.id)
      showStatusPopup({
        title: `Changed stage to ${resp.latest_interview_stage?.title}`,
        status: 'success',
      })

      onRefresh?.()
    } finally {
      onLoading(false)
    }
  }

  const changeStageToAutomatedScheduledStage = () => {
    showStatusPopup({
      title: 'Continue with automation?',
      status: 'warning',
      preventUserClose: true,
      description: (
        <>
          <Text fontWeight="bold">{stage.title}</Text> has a scheduling automation. An
          email with available interview slots will be sent to this candidate. Do you want
          to continue with automation?
        </>
      ),
      actions: popup => (
        <>
          <Button
            variant="secondary"
            onClick={() => {
              popup.hide()
              changeStageHandler(true)
            }}
          >
            Cancel automation
          </Button>
          <Button
            elevated
            onClick={() => {
              popup.hide()
              changeStageHandler()
            }}
          >
            Continue
          </Button>
        </>
      ),
    })
  }

  return (
    <MenuAction
      menuType={menuType}
      props={{
        ...itemProps,
        onClick:
          automaticInterviewScheduling &&
          stage?.automation_status &&
          !stage?.automation_disabled
            ? changeStageToAutomatedScheduledStage
            : () => changeStageHandler(),
      }}
    >
      Move to this stage
    </MenuAction>
  )
}
