import React, { useMemo, useState } from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import {
  useGetEmployeeMeetingChangelog,
  useGetMeetingNotesFeedback,
} from '@src/api/meetings'
import {
  ActionButton,
  Avatar,
  Box,
  DetailsCell,
  Flex,
  Group,
  HStack,
  Item,
  ItemSkeleton,
  Text,
  TextArea,
  TextSkeleton,
  Token,
  VStack,
  Widget,
  chain,
} from '@revolut/ui-kit'
import { formatDate, formatDateTime } from '@src/utils/format'
import { OneToOneMeetingChangelogItem } from '@src/interfaces/meetings'
import { capitalize, truncate } from 'lodash'
import SideBar from '@src/components/SideBar/SideBar'
import { Actionable } from '../components/Actionable'
import styled from 'styled-components'
import { selectorKeys } from '@src/constants/api'
import ButtonFilter from '@src/components/ButtonFilters/ButtonFilter'
import type { OptionInterface } from '@src/interfaces/selectors'
import { changeLocationToTitleMap } from './constants'
import { groupChangelogsByMonth } from './groupChangelogByMonth'

const StyledTextArea = styled(TextArea)`
  & > div {
    background-color: transparent !important;
    padding: 0px 16px 0 16px;

    :hover,
    :focus,
    :active,
    :focus-within {
      background-color: transparent !important;
    }
  }
`

export const OneToOneChangelog = () => {
  const [page, setPage] = useState(1)
  const { employeeId, meetingId, managerId } =
    useParams<{ employeeId: string; meetingId: string; managerId: string }>()
  const [changeLocationFilters, setChangeLocationFilters] = useState<OptionInterface[]>()
  const [changeTimeFilter, setChangeTimeFilter] = useState<OptionInterface[]>()

  const { data: changelogData, isLoading } = useGetEmployeeMeetingChangelog(
    Number(meetingId),
    {
      page,
      filters: [
        ...(changeLocationFilters
          ? [
              {
                columnName: 'change_location',
                filters: changeLocationFilters,
              },
            ]
          : []),
        ...(changeTimeFilter
          ? [
              {
                columnName: 'change_time',
                filters: changeTimeFilter,
              },
            ]
          : []),
      ],
    },
  )

  const { data: meetingNotesDetails, isLoading: isLoadingMeetingNotesDetails } =
    useGetMeetingNotesFeedback(Number(meetingId))

  const [openedItem, setOpenedItem] = useState<OneToOneMeetingChangelogItem>()

  const grouppedChangelog = useMemo(
    () =>
      groupChangelogsByMonth(
        changelogData?.results.filter(
          result => result.change_location !== 'attachments', // attachment cannot really change so just filtering them out
        ) || [],
      ),
    [changelogData?.results],
  )

  return (
    <PageWrapper>
      <PageHeader
        title="Change log"
        subtitle={
          !isLoadingMeetingNotesDetails ? (
            <Text color={Token.color.foreground}>
              {chain(
                meetingNotesDetails?.meeting.title,
                meetingNotesDetails?.meeting.start &&
                  formatDate(meetingNotesDetails?.meeting.start),
              )}
            </Text>
          ) : (
            <TextSkeleton maxWidth={200} />
          )
        }
        backUrl={pathToUrl(
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.ONE_TO_ONE.SUMMARY,
          {
            employeeId,
            managerId,
            meetingId,
          },
        )}
      />
      <PageBody>
        {isLoading ? (
          <Group>
            <ItemSkeleton />
            <ItemSkeleton />
          </Group>
        ) : (
          <VStack gap="s-16">
            <HStack gap="s-16">
              <ButtonFilter
                type="MultiSelect"
                title={truncate(
                  changeLocationFilters?.map(i => i.name).join(', ') || 'All Fields',
                  { length: 15 },
                )}
                value={changeLocationFilters}
                onChange={val => {
                  setChangeLocationFilters(val)
                }}
                selector={selectorKeys.feedback_changelog_change_locations}
              />

              <ButtonFilter
                useIcon="Calendar"
                type="DateRange"
                title="All time"
                value={changeTimeFilter}
                onChange={val => {
                  setChangeTimeFilter(val)
                }}
                selector={selectorKeys.none}
              />
            </HStack>
            {Object.entries(grouppedChangelog).map(([month, changelogs]) => (
              <VStack key={month} gap="s-16">
                <Text variant="emphasis1">{month}</Text>
                <Group>
                  {changelogs.map(changelog => (
                    <Item
                      key={changelog.id}
                      use="button"
                      onClick={() => setOpenedItem(changelog)}
                    >
                      <Item.Avatar>
                        <Avatar
                          useIcon={
                            changelog.change_type === 'created' ? 'Plus' : 'Pencil'
                          }
                        />
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>
                          {changelog.change_type === 'created'
                            ? 'Meeting notes'
                            : changeLocationToTitleMap[changelog.change_location]}
                        </Item.Title>
                        <Item.Description>
                          {chain(
                            `By ${changelog.author.full_name}`,
                            formatDate(changelog.change_time),
                          )}
                        </Item.Description>
                      </Item.Content>
                      <Item.Side>{capitalize(changelog.change_type)}</Item.Side>
                    </Item>
                  ))}
                </Group>
              </VStack>
            ))}
            {changelogData && changelogData.results.length < changelogData.count && (
              <Flex width="100%" justifyContent="center">
                <ActionButton
                  onClick={() => {
                    setPage(prev => prev + 1)
                  }}
                >
                  Load more
                </ActionButton>
              </Flex>
            )}
          </VStack>
        )}
      </PageBody>
      {openedItem && (
        <SideBar isOpen onClose={() => setOpenedItem(undefined)}>
          <VStack gap="s-16">
            {openedItem.change_location && (
              <DetailsCell>
                <DetailsCell.Title>Field changed</DetailsCell.Title>
                <DetailsCell.Content>
                  <Text variant="body2" color={Token.color.foreground}>
                    {changeLocationToTitleMap[openedItem.change_location]}
                  </Text>
                </DetailsCell.Content>
              </DetailsCell>
            )}
            <DetailsCell>
              <DetailsCell.Title>Time</DetailsCell.Title>
              <DetailsCell.Content>
                <Text variant="body2" color={Token.color.foreground}>
                  {formatDateTime(openedItem.change_time)}
                </Text>
              </DetailsCell.Content>
            </DetailsCell>
            {openedItem.change_type === 'updated' && (
              <Widget p="s-16">
                <VStack gap="s-8">
                  <Text>Previous value</Text>
                  <Box p="s-4">
                    {openedItem.change_location === 'action_items' &&
                      openedItem.change?.before?.content?.map(item => (
                        <Actionable disabled actionItem={item} key={item.value} />
                      ))}
                    {openedItem.change_location === 'talking_points' && (
                      <StyledTextArea
                        disabled
                        value={openedItem.change?.before?.content}
                      />
                    )}
                    {[
                      'report_personal_notes',
                      'summary',
                      'manager_personal_notes',
                      'performance_rating',
                    ].includes(openedItem.change_location) && (
                      <StyledTextArea
                        disabled
                        value={
                          typeof openedItem.change?.before === 'string'
                            ? openedItem.change?.before
                            : ''
                        }
                      />
                    )}
                  </Box>
                  <Text>New value</Text>
                  <Box p="s-4">
                    {openedItem.change_location === 'action_items' &&
                      openedItem.change?.after?.content?.map(item => (
                        <Actionable disabled actionItem={item} key={item.value} />
                      ))}
                    {openedItem.change_location === 'talking_points' && (
                      <StyledTextArea
                        disabled
                        value={openedItem.change?.after?.content}
                      />
                    )}
                    {[
                      'report_personal_notes',
                      'summary',
                      'manager_personal_notes',
                      'performance_rating',
                    ].includes(openedItem.change_location) && (
                      <StyledTextArea
                        disabled
                        value={
                          typeof openedItem.change?.after === 'string'
                            ? openedItem.change?.after
                            : ''
                        }
                      />
                    )}
                  </Box>
                </VStack>
              </Widget>
            )}
            <DetailsCell>
              <DetailsCell.Title>Changed by</DetailsCell.Title>
              <DetailsCell.Content>
                <Text variant="body2" color={Token.color.foreground}>
                  {openedItem.author.full_name}
                </Text>
              </DetailsCell.Content>
            </DetailsCell>
          </VStack>
        </SideBar>
      )}
    </PageWrapper>
  )
}
