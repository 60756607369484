import React from 'react'
import { Avatar, Item } from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { EditorView } from '@components/Editor/EditorView'

interface Props {
  growthPlan: GrowthPlanInterface
}

export const GeneralInfoWidget = ({ growthPlan }: Props) => (
  <Item>
    <Item.Avatar>
      <Avatar useIcon="Document" />
    </Item.Avatar>
    <Item.Content>
      <Item.Title>Description</Item.Title>
      <Item.Description>
        <EditorView html={growthPlan.description} />
      </Item.Description>
    </Item.Content>
  </Item>
)
